import React, { useEffect } from "react"

// Common Component //
import Header from "./../../components/headers/header-two"
import Layout from "./../../components/common/layout"

// Self Component //
import LoginForm from "./component/login.form"

// Services //
import authService from "../../services/auth.services"
import { useNavigate } from "react-router-dom"

const Login = () => {
  const navigate = useNavigate()

  useEffect(() => {
    validateAuth()
  })

  const validateAuth = async () => {
    const { token } = await authService.getCurrentUser()
    if (token) {
      navigate("/home")
    }
  }
  return (
    <React.Fragment>
      <Layout />
      <Header />
      <LoginForm />
    </React.Fragment>
  )
}

export default Login
