import React,{ useState,useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { MDBContainer,MDBRow,MDBCol } from "mdb-react-ui-kit";
import formikHelper from "../../../helper/formik.helper";
import * as Yup from "yup";

const bankList = [
    { id: 12, payment_name: "Maybank", account_number: "123840349901", holder_name:"XXX" },
    { id: 12, payment_name: "Hong Leong Bank Berhad", account_number: "123840349901", holder_name:"XXX"  },
    { id: 12, payment_name: "Public Bank Berhad", account_number: "123840349901", holder_name:"XXX"  },
  ];

  
const Topup = () => {
    const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
    const [data,setData] = useState({
        bank_number: bankList ? bankList[0].account_number : "",
        bank_name: bankList ? bankList[0].holder_name : "",
        amount: "",
        file: null,
    })

    const schema = Yup.object({
        amount: Yup.string()
          .required("required")
          .test("amount", "minimum RM20", (value) => value > 20),
        file: Yup.mixed()
          .required("required")
          .test(
            "file",
            "Your Image too Big",
            (value) => value && value.size <= 1024 * 1024 * 5
          )
          .test(
            "fileType",
            "Upload image only",
            (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
          ),
      });

    return(
        <MDBContainer>
            <div className="topup-container">
                <MDBRow className="topup-select-bank">
                    <MDBCol className="col-5 bank-label">Choose Bank :</MDBCol>
                    <MDBCol>
                        <MDBRow className="bank">
                            <select onChange={(e)=>{
                                // handlerBank(e.target.options)
                            }}>
                                {/* <option>Please Select</option> */}
                                {bankList && bankList.map((option, index) => {
                                return (
                                    <option key={index} value={option.id}>
                                    {option.payment_name}
                                    </option>
                                );
                                })}
                            </select>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                <Formik
                    enableReinitialize={true}
                    validateOnChange={true}
                    initialValues={data}
                    validationSchema={schema}
                    >
                    {({ setFieldValue, isSubmitting, handleReset, isValid, errors }) => (
                        <Form>

                        <MDBRow className="topup-detail">
                            <MDBCol className="col-5 topup-label">Bank Info :</MDBCol>
                            <MDBCol className="bank-info">
                                {formikHelper.renderInput(
                                    "bank_number",
                                    "",
                                    "text",
                                    "",
                                    "",
                                    false,
                                    errors
                                )}
                                {formikHelper.renderInput(
                                    "bank_name",
                                    "",
                                    "text",
                                    "",
                                    "",
                                    false,
                                    errors
                                )}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="topup-detail">
                            <MDBCol className="col-5 topup-label">Amount :</MDBCol>
                            <MDBCol>
                                {formikHelper.renderInput(
                                    "amount",
                                    "",
                                    "number",
                                    "",
                                    "",
                                    false,
                                    errors
                                )}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="topup-detail">
                            <MDBCol className="col-5 topup-label">Resit :</MDBCol>
                            <MDBCol>
                            <MDBRow className="upload-btn-wrapper">
                                {/* <button className="btn">MAX 2MB IMAGE</button> */}
                                <input
                                id="file"
                                name="file"
                                type="file"
                                className="custom-file-input"
                                accept="image/*"
                                // onChange={(event) => {
                                //     handlerChange(event);
                                // }}
                                />
                                {errors.file ? ( <div className="text-danger text-right no-padding error-message">{errors.file}</div>) : null}                  
                            </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        <div className="topup-btn">
                            {formikHelper.renderButton(
                            "Submit",
                            isSubmitting,
                            isValid,
                            "modalbox-btn"
                            )}
                        </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </MDBContainer>
    )
}

export default Topup