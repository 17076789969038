import { MDBContainer } from "mdb-react-ui-kit"
import React,{useState} from "react"
import Navbar from "../../components/element/navbar"

// Component
import Header from "../../components/headers/header-four"
import ModalBox from "../../components/modalbox"

//Local Component
import BetCard from "./component/bet.card"
import Influencer from "./component/influencer"
import Jackpot from "./component/jackpot"
import Zodiac from "./component/zodiac"

const Home = () => {
    const [ showGift , setShowGift ] = useState(false)
    console.log("showgift",showGift)
    return(
        <React.Fragment>
            <ModalBox show={showGift} setShowGift={setShowGift} />
            <Header />
            <MDBContainer className="home-container p-0">
                <BetCard />
                <Jackpot />
                <Zodiac />
                <Influencer />
            </MDBContainer>
            <Navbar setShowGift={setShowGift} />
        </React.Fragment>
    )
}

export default Home