import apiServices from "../../services/api.services"
import authServices from "../../services/auth.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const profile = createAsyncThunk("get/profile",
    async() => {
        const response = await apiServices.getProfile()
        return response.data
    }  
)

export const updateProfile = createAsyncThunk("update/profile",
    async({name,account_number,payment_name,phone_number},thunkAPI) => {
        try {
            const response = await authServices.UpdateProfile(name,account_number,payment_name,phone_number)
            return response.data
          } catch (error) {
            console.log("error",error)
            return thunkAPI.rejectWithValue(error);
          }
    }  
)
const initialState = {
    profile: {},
    updateProfile: {},
    message: "",
    Loading: true,
}

const profileSlice = createSlice({
    name:"profile",
    initialState,
    extraReducers:{
        [profile.pending]:(state,action)=>{
            state.Loading = true
        },
        [profile.fulfilled]:(state,action)=>{
            state.Loading = false
            state.profile = action.payload
        },
        [updateProfile.pending]:(state,action)=>{
            state.Loading = true
        },
        [updateProfile.fulfilled]:(state,action)=>{
            return{
                Loading: false,
                message: action.payload.message
            }
        },
        [updateProfile.rejected]:(state,action)=>{
            return{
                Loading: false,
                message: action.payload.message
            }
        },
    }
})

const { reducer } = profileSlice
export default reducer