import { MDBContainer, MDBRow, MDBIcon, MDBCol, MDBBtn } from "mdb-react-ui-kit"
import { Link } from "react-router-dom";
import walletIcon from "../../assets/images/wallet.png"
import Logo from "../../assets/images/main-logo.png"
const Header = () => {
  
  return (
    <MDBContainer className="header-four-container">
      <MDBRow className="header-row">
        <MDBCol className="header-row label"><img src={Logo} width="80" height="65"/></MDBCol>
        <MDBCol className="header-row button">
             <Link to="/wallet"><MDBBtn> <img className="icon" src={walletIcon} width="25" height="25"/>&nbsp;Wallet</MDBBtn></Link>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default Header
