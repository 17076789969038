import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { Trans,useTranslation } from "react-i18next"

// Common Component //
import Header from "../../components/headers/header-three";
import SideBar from "../../components/sidebars/sidebar-one";
import Navbar from "../../components/element/navbar";
import GuestModalBox from "../../components/modalbox/index";

//local Component//
import BankDetail from "./component/bank.detail";
import ConfirmBox from "./component/confirmation";

// Image //
import Layout from "../../components/common/layout";
import apiServices from "../../services/api.services";

const TopUp = () => {
  const {t,i18n} = useTranslation()
  const [id, setId] = useState({
    id: "",
    account_number: "",
    payment_name: "",
  });
  const [bankList, setBankList] = useState();
  const [guestModalBox, setGuestModalBox] = useState(false);

  useEffect(() => {
    getBankList();
  }, []);

  const getBankList = async () => {
    const response = await apiServices.getCompanyBankList();
    setBankList(response.data);
    setId({
      id: response.data[0].id,
      account_number: response.data[0].account_number,
      payment_name: response.data[0].payment_name,
    })
  };

  return (
    <React.Fragment>
      <MDBContainer className="wallet-container">
        <Layout />
        <Header />
        <SideBar setGuestModalBox={setGuestModalBox} />
        <Navbar />
        <GuestModalBox
          guestModalBox={guestModalBox}
          setGuestModalBox={setGuestModalBox}
        />
        <MDBContainer style={{ paddingTop: "5em", paddingBottom: "10em" }}>
          <MDBRow>
            <MDBCol className="wallet-title">{t("Select Bank")}</MDBCol>
          </MDBRow>
          <MDBContainer className="wallet-bank-section">
            {bankList ? (
              <>
                {bankList.map((banklist, index) => {
                  return (
                    <MDBCol
                      key={index}
                      className="wallet-bank-section-item"
                      onClick={() => {
                        setId(banklist);
                      }}
                    >
                      <MDBCol className="col-5">
                        <img src={banklist.media}></img>
                      </MDBCol>
                      <MDBCol className="col-7">
                        <MDBRow>{banklist.payment_name}</MDBRow>
                        <MDBRow>{banklist.holder_name}</MDBRow>
                        <MDBRow>{banklist.account_number}</MDBRow>
                      </MDBCol>
                    </MDBCol>
                  );
                })}
              </>
            ) : (
              <MDBCol className="wallet-bank-section-item"></MDBCol>
            )}
          </MDBContainer>
          <BankDetail id={id} setId={setId} />
          <MDBContainer className="wallet-box">
            <Trans>
              <label>{t("Must Read")}</label>
              <h3>{t("Notice")}</h3>
              <ul>
                <li>
                  {t("Do not write any sensitive key word in the receipt.<br>(for example : casino) will be reject and no refund")}
                </li>
                <li>{t("The amount of transaction for top up must be minimum RM20")}</li>
                <li>
                  {t("Make sure to upload the bank slip must be clear and in full size receipt.")}
                </li>
                <li>
                  {t("The bank slip receipt must be uploaded into the platform within 24 Hours or else the receipt consider Void.")}
                </li>
                <li>{t("Time for upload bank slip from 11am to 10.30pm.")}</li>
              </ul>
            </Trans>
          </MDBContainer>
        </MDBContainer>
      </MDBContainer>
    </React.Fragment>
  );
};

export default TopUp;
