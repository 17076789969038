import React, { useState, useEffect, useRef } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import formikHelper from "../../../helper/formik.helper";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import authServices from "../../../services/auth.services";
import _ from "lodash";
import GuestModalBox from "../../../components/modalbox/index";
import { useDispatch, useSelector } from "react-redux";
import { topup } from "../../../slices/wallet/walletSlice";
import { useTranslation } from "react-i18next"


const BankDetail = ({ id, setId }) => {
  const dispatch = useDispatch();
  const {t,i18n} = useTranslation()
  const setting_id = id;
  const [fileName, setFileName] = useState();
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const [data, setData] = useState({
    bank_type: "",
    amount: "",
    file: null,
  });
  const [guestModalBox, setGuestModalBox] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (id) {
      setData({
        bank_type: id.account_number,
        amount: "",
        file: null,
      });
    } else {
      setData({ bank_type: "", amount: "", file: null });
    }
  }, [id]);

  const schema = Yup.object({
    bank_type: Yup.string().required(t("required")),
    amount: Yup.string()
      .required(t("required"))
      .test("amount", t("minimum RM20"), (value) => value > 19),
    file: Yup.mixed()
      .required(t("required"))
      .test(
        "file",
        t("Your Image too Big"),
        (value) => value && value.size <= 1024 * 1024 * 5
      )
      .test(
        "fileType",
        t("Upload image only"),
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      ),
  });

  async function onSubmit(
    data,
    setSubmitting,
    setting_id,
    setFileName,
    setData,
    setFieldError,
    resetForm,
    show
  ) {
    const formData = new FormData();
    formData.append("amount", data.amount);
    formData.append("receipt", data.file);
    formData.append("setting_id", setting_id);
    try {
      const { data: response } = await authServices.Topup(
        data.amount,
        data.file,
        data.bank_type,
        setting_id,
        formData
      );
      if (response) {
        const message = response.message
        setFileName("");
        setId("");
        setData({
          bank_type: "",
          amount: "",
          file: null,
        });
        sweetAlertHelper({
          title: t("Notification"),
          html: t(`message`,{message}),
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors;
        // toast.dismiss()
        if (errors && Object.keys(errors).length > 0) {
          // eslint-disable-next-line array-callback-return
          Object.keys(errors).map((item, i) => {
            setFieldError(item, errors[item]);
          });
        }
      } else {
        if (ex.response.status === 401) {
          setGuestModalBox(true);
        } else {
          sweetAlertHelper({
            title: "Notification",
            html: `Your Topup Request Fail.`,
            showCancelButton: false,
            showConfirmButton: true,
            allowOutsideClick: false,
          });
        }
      }
    }
    setSubmitting(false);
  }

  const handlerChange = (event) => {
    if (event.target.files[0].size > 1024 * 1024 * 5) {
      return setFileName(t("Uploaded file is too big."));
    } else if (event.target.files[0].type === "image/jpeg") {
      return setFileName(event.target.files[0].type);
    } else if (event.target.files[0].type === "image/png") {
      return setFileName(event.target.files[0].type);
    } else if (event.target.files[0].type === "image/jpg") {
      return setFileName(event.target.files[0].type);
    } else {
      return setFileName(t("Upload image only"));
    }
  };
  const handleSubmit = (
      formValue,
      setSubmitting,
      setting_id,
      setFileName,
      setData,
      setFieldError,
      resetForm,
      show
    ) => {
    console.log("formValue",formValue)
    const formData = new FormData();
    formData.append("amount", formValue.amount);
    formData.append("receipt", formValue.file);
    formData.append("setting_id", setting_id);
      // const { amount } = formValue
      dispatch(topup({formData}))
        .unwrap()
        .then(() => {
          // dispatch(wallet()).unwrap()
          sweetAlertHelper({
            title: "Notification",
            html: `Topup Request Send`,
            showCancelButton: false,
            showConfirmButton: true,
            allowOutsideClick: false,
          });
          setFileName("");
          setId("");
          setData({
            bank_type: "",
            amount: "",
            file: null,
          });
        })
        .catch((e) => {
          if (e) {
            const errors = e
            // toast.dismiss()
            if (errors && Object.keys(errors).length > 0) {
              // eslint-disable-next-line array-callback-return
              Object.keys(errors).map((item, i) => {
                setFieldError(item, errors[item]);
              });
            }
          }
        });
    }

  return (
    <React.Fragment>
      <MDBContainer className="wallet-box">
        <GuestModalBox
          refresh={refresh}
          setRefresh={setRefresh}
          guestModalBox={guestModalBox}
          setGuestModalBox={setGuestModalBox}
        />
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          initialValues={data}
          validationSchema={schema}
          onSubmit={(values, { setFieldError, resetForm }) => {
            // console.log("onsubmit", values);
            handleSubmit(
              values,
              data,
              setting_id.id,
              setFileName,
              setData,
              setFieldError
            );

            resetForm({ values: "" });
          }}
        >
          {({ setFieldValue, isSubmitting, handleReset, isValid, errors }) => (
            <Form>
              <MDBRow className="wallet-box-row">
                <MDBCol className="col-5 wallet-box-label">
                  {t("I have bank into")} :
                </MDBCol>
                <MDBCol>
                  <MDBRow>
                    {formikHelper.renderInput(
                      "bank_type",
                      "",
                      "text",
                      "",
                      "",
                      true,
                      errors
                    )}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow className="wallet-box-row">
                <MDBCol className="col-5 wallet-box-label">{t("Amount")} :</MDBCol>
                <MDBCol>
                  {formikHelper.renderInput(
                    "amount",
                    "",
                    "number",
                    "",
                    "",
                    false,
                    errors
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow className="wallet-box-row">
                <MDBCol className="col-5 wallet-box-label">
                  {t("Upload Resit")} :
                </MDBCol>
                <MDBCol>
                  <MDBRow className="upload-btn-wrapper">
                    <button className="btn">{t("MAX 2MB IMAGE")}</button>
                    <input
                      id="file"
                      name="file"
                      type="file"
                      className="custom-file-input"
                      accept="image/*"
                      onChange={(event) => {
                        setFieldValue("file", event.target.files[0]);
                        handlerChange(event);
                      }}
                    />
                    {fileName && (
                      <input
                        className={
                          fileName === "Uploaded file is too big."
                            ? "text-danger text-right no-padding error-message"
                            : fileName === "Upload image only"
                            ? "text-danger text-right no-padding error-message"
                            : "no-padding error-message"
                        }
                        name="file_name"
                        label="file name"
                        placeholder=""
                        readOnly
                        value={fileName}
                      />
                    )}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow className="wallet-btn">
                {formikHelper.renderButton(
                  t("Submit"),
                  isSubmitting,
                  isValid,
                  "modalbox-btn"
                )}
              </MDBRow>
            </Form>
          )}
        </Formik>
      </MDBContainer>
    </React.Fragment>
  );
};

export default BankDetail;
