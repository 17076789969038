import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { DatePicker } from "antd";
import styled from "styled-components";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import useInfiniteScroll from "./component/use.infinite.scroll";
import { useTranslation } from "react-i18next"

// Common Component //
import Header from "../../components/headers/header-three";
import SideBar from "../../components/sidebars/sidebar-one";
import ProtectedRoute from "../../components/common/protected.route";

// Image //
import { BGMain } from "../../assets/images/index";
import Navbar from "../../components/element/navbar";
import Layout from "../../components/common/layout";
import Loader from "../../assets/images/Loader.svg";
import apiServices from "../../services/api.services";
import authService from "../../services/auth.services";
import { useDispatch, useSelector } from "react-redux";
import { history, removeWalletHistory } from "../../slices/wallet/translationSlice";

export const StyleWrapperDatePicker = styled.div`
  .ant-picker-panel {
    &:last-child {
      width: 0;
      .ant-picker-header {
        position: absolute;
        right: 0;
        .ant-picker-header-prev-btn,
        .ant-picker-header-view {
          visibility: hidden;
        }
      }

      .ant-picker-body {
        display: none;
      }

      @media (min-width: 1000px) {
        width: 280px !important;
        .ant-picker-header {
          position: relative;
          .ant-picker-header-prev-btn,
          .ant-picker-header-view {
            visibility: initial;
          }
        }

        .ant-picker-body {
          display: block;
        }
      }
    }
  }
`;

const Transaction = () => {
  const dispatch = useDispatch()
  const {t,i18n} = useTranslation()
  const walletDetail = useSelector((state) => state.history.history)
  const Loading = useSelector((state) => state.history.Loading)
  const onLoad = useSelector((state) => state.history.onLoad)
  const isEnd = useSelector((state) => state.history.isEnd)
  const Fetching = useSelector((state) => state.history.Fetching)
  const [endDate, setEndDate] = useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState(
    moment().subtract(0, "days").format("YYYY-MM-DD")
  );
  const [length,setLength] = useState(10)
  const [start, setStart] = useState(10);
  const [showRemark, setShowRemark] = useState();
  // const [isEnd, setIsEnd] = useState(false);
  // const [isFetching, setIsFetching] = useState(Fetching);
  // const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const { token } = authService.getCurrentUser();

  const panelRender = (panelNode) => (
    <StyleWrapperDatePicker>{panelNode}</StyleWrapperDatePicker>
  );

  // useEffect(()=>{
  //   if (token && isFetching) {
  //     let sumStart = start + 10; // skip 10 every load
  //     dispacth(nextHistory({length,start}))
  //     setStart(sumStart);
  //     setIsFetching(false);
  //     if(walletDetail.length !== walletDetail.length){
  //       setIsEnd(true)
  //     }
  //   }
  // },[isFetching])

  useEffect(()=>{
    if(token){
      dispatch(history({length}))
      return () => dispatch(removeWalletHistory())
    }
  },[dispatch])

  // const handleScroll = useCallback(async () => {
  //   if (isEnd) {
  //     return;
  //   }
  //   if (
  //     document.documentElement.scrollTop + window.innerHeight >=
  //     document.scrollingElement.scrollHeight
  //   ) {
  //     setIsFetching(true);
  //   } else {
  //     return;
  //   }
  // }, []);
  // console.log("fetching",isFetching)
  // console.log("walletDetail",walletDetail)

  // useEffect(() => {
  //   if (!onLoad) {
  //     if (!isEnd) {
  //       window.addEventListener("scroll", handleScroll);
  //       return () => window.removeEventListener("scroll", handleScroll);
  //     } else {
  //       window.removeEventListener("scroll", handleScroll, true);
  //     }
  //   }
  //   return;
  // }, [isEnd, onLoad]);

  const fecthData = () => {
      let sumStart = start + 10; // skip 10 every load
      dispatch(history({length,start}))
      setStart(sumStart);
  }


  // console.log("detail",walletDetail)
  return (
    <React.Fragment>
      <MDBContainer className="wallet-container">
        {/* <ProtectedRoute /> */}
        <Layout />
        <Header reload={reload} />
        <SideBar />
        <Navbar />
        <MDBContainer className="wallet-transaction-container2">
          <MDBContainer>
            <MDBRow className="wallet-transaction-label">
              <label>{t("Transaction History")}</label>
            </MDBRow>
            {/* <DatePicker.RangePicker
              panelRender={panelRender}
              onChange={(values, dateString) => {
                setStartDate(dateString[0]);
                setEndDate(dateString[1]);
              }}
              // onOpenChange={onCloseDateRange}
              defaultValue={[moment().subtract(2, "days"), moment()]}
              disabled={true}
            /> */}
          </MDBContainer>
          <MDBContainer className="wallet-transaction-history">
            <MDBRow className="wallet-transaction-row">
              <MDBCol className="col-4">{t("Date")}</MDBCol>
              <MDBCol className="col-4">{t("Amount")}</MDBCol>
              <MDBCol className="col-4">{t("Status")}</MDBCol>
            </MDBRow>
            <div className="line"></div>
            {/* <MDBContainer className="scroll" id="scroll"> */}
            <InfiniteScroll
                dataLength={walletDetail.length}
                next={fecthData}
                hasMore={true}
                Loader={
                  <MDBRow className="loader-container-2">
                    <img
                      className="loader"
                      src={Loader}
                      alt=""
                      width="25"
                      height="60"
                    />
                  </MDBRow>
                }
                endMessage={
                  <MDBRow className="d-block p-4">{t("Loading Complete")}</MDBRow>
                }
                style={{ overflow: "hidden"}}
              >
              {walletDetail.map((p, index) => (
                  <div key={index}>
                    <MDBRow
                      className="wallet-transaction-row"
                      id={p.id}
                      onClick={() => setShowRemark(p.id)}
                    >
                      <MDBCol className="col-4">
                        {p.date}/{moment().format("YYYY")}
                        <br />
                        {p.time}
                      </MDBCol>
                      <MDBCol className="col-4">{p.amount}</MDBCol>
                      <MDBCol
                        className={
                          p.status === "pending"
                            ? "col-4 wallet-transaction-status-pending"
                            : p.status === "active"
                            ? "col-4 wallet-transaction-status-success"
                            : "col-4 wallet-transaction-status-fail"
                        }
                      >
                        {p.description}
                      </MDBCol>
                    </MDBRow>
                    {showRemark == p.id && p.status == "fail" && (
                      <div className="wallet-transaction-remark">
                        {t("Remark")} :{" "}
                        {p.remark ? p.remark.substring(0, 20) : "no remark"}
                      </div>
                    )}
                    <div className="line"></div>
                  </div>
                ))}
              </InfiniteScroll>
              {/* {!Loading && walletDetail.length > 0 ? (
                walletDetail.map((p, index) => (
                  <div key={index}>
                    <MDBRow
                      className="wallet-transaction-row"
                      id={p.id}
                      onClick={() => setShowRemark(p.id)}
                    >
                      <MDBCol className="col-4">
                        {p.date}/{moment().format("YYYY")}
                        <br />
                        {p.time}
                      </MDBCol>
                      <MDBCol className={p.type === "topup" 
                      ? "col-4 wallet-transaction-status-topup" 
                      : p.type === "withdrawal" 
                      ? "col-4 wallet-transaction-status-withdrawl"
                      : "wallet-transaction-status-other"}>
                        {p.type === "topup" ? "+" + p.amount : p.amount }
                        </MDBCol>
                      <MDBCol
                        className={
                          p.status === "pending"
                            ? "col-4 wallet-transaction-status-pending"
                            : p.status === "active" && p.type === "topup"
                            ? "col-4 wallet-transaction-status-topup"
                            : p.status === "active" && p.type === "withdrawal"
                            ? "wallet-transaction-status-withdrawl"
                            : p.status === "fail"
                            ? "col-4 wallet-transaction-status-fail"
                            : "wallet-transaction-status-other"
                        }
                      >
                        {p.description}
                      </MDBCol>
                    </MDBRow>
                    {showRemark == p.id && p.status == "fail" && (
                      <div className="wallet-transaction-remark">
                        {t("Remark")} 
                        {" "}{p.remark ? p.remark.substring(0, 20) : "no remark"}
                      </div>
                    )}
                    <div className="line"></div>
                  </div>
                ))
              ) : 
              walletDetail.length < 0 ? 
              (
                <MDBRow className="wallet-transaction-row">
                    <MDBCol className="col-12">No recent activity</MDBCol>
                </MDBRow>
              ) : (
                <MDBRow className="loader-container">
                  <img
                    className="loader"
                    src={Loader}
                    alt=""
                    width="50"
                    height="100"
                  />
                  {/* LOADING.... */}
                {/* </MDBRow> */}
              {/* )} */}

              {Fetching ? (
                <MDBRow className="loader-container-2">
                  <img
                    className="loader"
                    src={Loader}
                    alt=""
                    width="25"
                    height="60"
                  />
                </MDBRow>
              ) : walletDetail.length === 0 ? 
                <MDBRow style={{justifyContent:"center",padding:"4em",height:"35em"}}>
                  {t("No Recent Activity")}...
                </MDBRow>
                 : ""}

              {isEnd.length === 0 && 
                <MDBRow className="d-block p-4">{t("Loading Complete")}</MDBRow>
              }
            </MDBContainer>
          </MDBContainer>
        </MDBContainer>
      {/* </MDBContainer> */}
    </React.Fragment>
  );
};

export default Transaction;
