import apiServices from "../../services/api.services"
import authServices from "../../services/auth.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

export const changePassword = createAsyncThunk("changePassword",
    async({password,new_password,confirm_new_password},thunkAPI) => {
        try {
            const response = await authServices.ChangePassword(password,new_password,confirm_new_password)
            return response.data
        } catch (error) {
            console.log("error",error)
            return thunkAPI.rejectWithValue(error);
        }
    }  
)

const initialState = {
    changePassword: {},
    message: ""
}
const changePasswordSlice = createSlice({
    name:"changePassword",
    initialState,
    extraReducers:{
        [changePassword.pending]:(state,action)=>{
            state.Loading = true
        },
        [changePassword.fulfilled]:(state,action)=>{
            return{
                Loading: false,
                message: action.payload.message
            }
        },
        [changePassword.rejected]:(state,action)=>{
            return{
                Loading: false,
                message: action.payload.message
            }
        },
    }
})

const { reducer } = changePasswordSlice
export default reducer