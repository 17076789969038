import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { bubble as Menu } from "react-burger-menu";
import { SidebarLogoV2 } from "../../assets/images";
import moment from "moment";
import _, { set } from "lodash";
import { useNavigate } from "react-router-dom";
import apiServices from "../../services/api.services";
import authServices from "../../services/auth.services";
import { useDispatch, useSelector } from "react-redux";
import { profile } from "../../slices/profile/profileSlice"
import { logout } from "../../slices/auth"
import { useTranslation } from "react-i18next"

const SideBar = ({ reload, setGuestModalBox }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t,i18n} = useTranslation()
  const wallet = useSelector((state) => state.wallet.wallet);
  const profileInfo = useSelector((state) => state.profile.profile);
  const [balance, setBalance] = useState(0);
  // const [profileInfo, setProfileInfo] = useState({});
  const registerDate = Object.keys(profileInfo).length > 0 ? new Date(profileInfo.register_at).toDateString() : 0
  const [iconList, setIconList] = useState([
    {
      text: t("Home"),
      icon: "home",
      active: false,
      url: "/",
      requiredLogin: false,
    },
    {
      text: t("Promotion"),
      icon: "chart-line",
      active: false,
      url: "/promotion",
      requiredLogin: false,
    },
    {
      text: t("Today Report"),
      icon: "layer-group",
      active: false,
      url: "/report",
      requiredLogin: true,
    },
    {
      text: t("Trade History"),
      icon: "flag",
      active: false,
      url: "/history",
      requiredLogin: true,
    },
    {
      text: t("My Referral"),
      icon: "users",
      active: false,
      url: "/referral",
      requiredLogin: true,
    },
    {
      text: t("Sign Out"),
      icon: "sign-out-alt",
      active: false,
      url: "/signout",
      requiredLogin: true,
    },
  ]);

  const [show, setShow] = useState(false);
  const { token } = authServices.getCurrentUser();
  const [menuOpen, setMenuOpen] = useState(false);


  useEffect(()=>{
    if(token){
      dispatch(profile())
    }
  },[])
  
  // const getWalletBalance = async () => {
  //   const response = await apiServices.getWalletBalance();
  //   if (response) {
  //     setBalance(response.data.wallet_balance);
  //   }
  // };

  // const getProfile = async () => {
  //   const { data: response } = await apiServices.getProfile();
  //   if (response) {
  //     setProfileInfo(response);
  //   }
  // };

  // console.log("slidebar", reload);
  // useEffect(() => {
  //   if (reload) {
  //     getWalletBalance();
  //     getProfile();
  //   }
  //   if (balance === 0 && token) {
  //     getWalletBalance();
  //     getProfile();
  //   }
  // }, [reload]);

  const handleClickEvent = (url) => {
    if (_.isEqual(url, "/signout")) {
      // authServices.logout();
      dispatch(logout());
      window.location.reload()
      // navigate("/");
    } else {
      // navigate
      navigate(url);
    }
  };
  const handleOnOpen = () => {
    // console.log("handleonopen");
    setMenuOpen(true);
  };
  const handleOnClose = () => {
    // console.log("handleonclose");
    setMenuOpen(false);
  };
  // console.log("menu open ", menuOpen);
  return (
    <Menu
      isOpen={menuOpen}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      // onStateChange={() => setReload(!reload)}
    >
      {/* <GuestModalBox
        refresh={refresh}
        setRefresh={setRefresh}
        guestModalBox={guestModalBox}
        setGuestModalBox={setGuestModalBox}
      /> */}
      <MDBContainer className="sidebar-container">
        <MDBRow className="sidebar-logo-row">
          <img src={SidebarLogoV2} alt="" />
        </MDBRow>
        <MDBRow className="sidebar-info-row">
          <MDBCol className="sidebar-info-player">
            <span className="sidebar-info-player-name">
              {Object.keys(profileInfo).length > 0
                ? profileInfo.name || ""
                : ""}
            </span>
            {token && (
              <span className="sidebar-info-register">
                {t("register at",{registerDate})}
                {/* {Object.keys(profileInfo).length > 0
                  ? new Date(profileInfo.register_at).toDateString() : 0} */}
              </span>
            )}
          </MDBCol>
          <MDBCol className="sidebar-info-vip">
            <span className="sidebar-info-vip-text">
              {t("VIP")}{" "}
              {Object.keys(profileInfo).length > 0
                ? profileInfo.vip_rank || 0
                : 0}
            </span>
          </MDBCol>
        </MDBRow>
        <MDBRow className="sidebar-info-wallet">
          <MDBRow className="sidebar-info-wallet-box g-0">
            <MDBCol className="sidebar-info-left" size={6}>
              <span className="sidebar-info-balance">{t("My Balance")}</span>
              <span className="sidebar-info-wallet-amount">
                RM {wallet ? wallet.wallet_balance : "0.00"}
              </span>
            </MDBCol>
            <MDBCol className="sidebar-info-right" size={6}>
              <span
                className="sidebar-info-topup"
                onClick={() => {
                  navigate("/topup");
                }}
              >
                {t("Top up")}
              </span>
            </MDBCol>
          </MDBRow>
        </MDBRow>
        <MDBRow className="sidebar-menu g-0">
          {iconList.map((data, index) => {
            if (token) {
              return (
                <MDBRow
                  key={`sidebar-row-${index}`}
                  className={`sidebar-icon-row ${
                    window.location.pathname === data.url ? "active" : ""
                  }`}
                  onClick={() => {
                    handleClickEvent(data.url);
                  }}
                >
                  <MDBIcon
                    className="sidebar-menu-icon"
                    fas
                    size="lg"
                    icon={data.icon}
                  />
                  <span className="sidebar-menu-text"> {data.text} </span>
                </MDBRow>
              );
            } else {
              if (!data.requiredLogin) {
                return (
                  <MDBRow
                    key={`sidebar-row-${index}`}
                    className={`sidebar-icon-row ${
                      window.location.pathname === data.url ? "active" : ""
                    }`}
                    onClick={() => {
                      handleClickEvent(data.url);
                    }}
                  >
                    <MDBIcon
                      className="sidebar-menu-icon"
                      fas
                      size="lg"
                      icon={data.icon}
                    />
                    <span className="sidebar-menu-text"> {data.text} </span>
                  </MDBRow>
                );
              }
            }
          })}
          {!token && (
            <MDBRow
              key={`sidebar-row-login`}
              className={`sidebar-icon-row`}
              onClick={() => {
                // console.log("setting onclick");
                setMenuOpen(false);
                setGuestModalBox(true);
              }}
            >
              <MDBIcon className="sidebar-menu-icon" fas size="lg" />
              <span
                className="sidebar-menu-text"
                style={{ color: "deepskyblue" }}
              >
                {t("Click here to login")}
              </span>
            </MDBRow>
          )}
        </MDBRow>
      </MDBContainer>
    </Menu>
  );
};

export default SideBar;
