import { MDBContainer } from "mdb-react-ui-kit"
import React,{useState} from "react"


// Component
import Header from "../../components/headers/header-four"
import Navbar from "../../components/element/navbar"
import ModalBox from "../../components/modalbox"

//Local Component
import CarouselPromotion from "./component/carousel"

const Promotion = () => {
    const [ showGift , setShowGift ] = useState(false)
    console.log("showgift",showGift)
    return(
        <React.Fragment>
            <ModalBox show={showGift} setShowGift={setShowGift} />
            <Header />
            <MDBContainer className="promotion-container p-0">
                <CarouselPromotion />
            </MDBContainer>
            <Navbar setShowGift={setShowGift} />
        </React.Fragment>
    )
}

export default Promotion