import React from "react"
import { Route, Navigate } from "react-router-dom"
import authService from "../../services/auth.services"

const ProtectedRoute = () => {
  const { token } = authService.getCurrentUser()

  return token ? "" : <Navigate to="/" replace></Navigate>
}

export default ProtectedRoute
