import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MDBContainer } from "mdb-react-ui-kit";
import BetPlace from "./component/bet.place";
import Navbar from "../../components/element/navbar-two";
import Header from "../../components/headers/header-five";
import BetResult from "./component/bet.result";


const Bet = () => {
  const { min } = useParams();
  const [chip,setChip] = useState(0);
  const [totalChip, setTotalChip] = useState(0);

  console.log("totalChip",totalChip)
  console.log("chip",chip)

  const handleClick = (chipNumber) => {
    console.log("chipNumber",chipNumber)
    setChip(chipNumber)
  }

  const increaseTotalChip = (chipNumber) => {
    setTotalChip(function(preChipNumber){
      return (preChipNumber += chipNumber)
    })
  }

  const decreaseTotalChip = (chipNumber) => {
    if(totalChip > 0){
      setTotalChip(function(preChipNumber){
        return (preChipNumber -= chipNumber)
      })
    }
  }

  const clearAllTotalChip = () => {
    setTotalChip(0)
  }

  const dealAllChip = () => {

  }

  return (
    <React.Fragment>
      <Header />
      <MDBContainer className="bet-page">
        <MDBContainer className="betting-container g-0">
          <BetResult />
          <BetPlace chip={chip} totalChip={totalChip} increaseTotalChip={increaseTotalChip}/>
         </MDBContainer>
      </MDBContainer>
      <Navbar chip={chip} totalChip={totalChip} handleClick={handleClick}  decreaseTotalChip={decreaseTotalChip} clearAllTotalChip={clearAllTotalChip}/>
    </React.Fragment>
  );
};

export default Bet;
