// Module Import //
import React, { useState } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Customize Library //
import FormikForm from "../../../helper/formik.helper";
import { sweetAlertHelper } from "../../../helper/sweetalert.helper";
import authServices from "../../../services/auth.services";
import { useTranslation } from "react-i18next"

const LoginForm = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation()
  const [data, setData] = useState({
    username: "",
    password: "",
  });

  const loginSchema = Yup.object({
    username: Yup.string().required(t("Enter username")),
    password: Yup.string().required(t("Enter password")),
  });

  async function onSubmit(data, setFieldError, navigate, setSubmitting) {
    try {
      const response = await authServices.login(data.username, data.password);
      if (response) {
        // ----- Removed base on gum request ---- //
        // sweetAlertHelper({
        //   title: "Notification",
        //   html: `Login successfully`,
        //   showCancelButton: false,
        //   showConfirmButton: false,
        // });
        localStorage.setItem("username", data.username);
        navigate("/home");
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        // const error = ex.response.data
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          // console.log("errors ", errors)
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              // alert(item + " " + errors[item]);
              setFieldError(item, errors[item]);
            });
          }
        }
        // setFieldError("password", error.message)
      }
    }
    setSubmitting(false);
  }
  return (
    <MDBContainer className="login-container size-control g-0">
      <Formik
        initialValues={data}
        validationSchema={loginSchema}
        onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
          onSubmit(
            values,
            setFieldError,
            navigate,
            setSubmitting,
            resetForm,
            data
          );
        }}
      >
        {({ setFieldValue, errors, isSubmitting, isValid }) => (
          <Form>
            {FormikForm.renderInput(
              "username",
              t("Your Username"),
              "text",
              "",
              "",
              false,
              errors
            )}
            {FormikForm.renderInput(
              "password",
              t("Password"),
              "text",
              "new-password",
              "key",
              false,
              errors
            )}
            {/* <MDBRow>
              <MDBCol className="login-text">
                <span
                  onClick={() => {
                    window.open("https://direct.lc.chat/14022327/")
                  }}
                  className="mediumgrey-font"
                  style={{ color: "#0d6efd" }}
                >
                  Forgot Password?
                </span>
              </MDBCol>
            </MDBRow> */}
            {FormikForm.renderButton(t("Login"), isSubmitting, isValid)}
          </Form>
        )}
      </Formik>
    </MDBContainer>
  );
};

async function onSubmit(data, setFieldError, navigate, setSubmitting) {
  try {
    // const response = await authServices.login(data.username, data.password)
    const response = {};
    if (response) {
      // const profileRes = await userServices.getProfile()
      const profileRes = {};
      const profile = profileRes.data || "";
      localStorage.setItem("username", data.username);
      localStorage.setItem("trade_pin", profile.data.trade_pin || "");
      sweetAlertHelper({
        title: "通知",
        html: `登入成功`,
        showCancelButton: false,
        showConfirmButton: false,
      });
      navigate("/dashboard");
    }
  } catch (ex) {
    if (ex && Object.keys(ex).length > 0) {
      // const error = ex.response.data
      if (ex.response.status === 422) {
        const errors = ex.response.data.errors;
        // console.log("errors ", errors)
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            // alert(item + " " + errors[item]);
            setFieldError(item, errors[item]);
          });
        }
      }
      // setFieldError("password", error.message)
    }
  }
  setSubmitting(false);
}

export default LoginForm;
