// Module Import //
import React from "react"

// Common Component //
import Header from "./../../components/headers/header-one"
import Layout from "./../../components/common/layout"

// Self Component //
import RegisterForm from "./component/register.form"

const Register = () => {
  return (
    <React.Fragment>
      <Layout />
      <Header />
      <RegisterForm />
    </React.Fragment>
  )
}

export default Register
