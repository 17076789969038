import apiServices from "../../services/api.services"
import authServices from "../../services/auth.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const home = createAsyncThunk("get/home",
    async() => {
        const response = await apiServices.getHome()
        return response.data
    }  
)

const initialState = {
    home: {},
    Loading: true
}
// home ? { Loading: true, home } : { home: null } 
const homeSlice = createSlice({
    name:"home",
    initialState,
    extraReducers:{
        [home.pending]:(state,action)=>{
            state.Loading = true
        },
        [home.fulfilled]:(state,action)=>{
            state.Loading = false
            state.home = action.payload
        },
    }
})

const { reducer } = homeSlice
export default reducer