// Module Import //
import React, { useState,useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Customize Library //
import FormikForm from "./../../../helper/formik.helper";
import { sweetAlertHelper } from "./../../../helper/sweetalert.helper";
import authServices from "../../../services/auth.services";
import { useTranslation } from "react-i18next";

const RegisterForm = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState({
    username: "",
    password: "",
    confirm_password: "",
    phone_number: "60",
    invitation_code: searchParams.get("referral_code")
      ? searchParams.get("referral_code")
      : "",
  });

  const registerSchema = Yup.object({
    username: Yup.string()
      .required(t("Enter username"))
      .min(6, t("Please enter more than 6 character"))
      .matches(
        /^[a-zA-Z0-9]+$/,
        t("Username cannot contain white space and special character")
        // /^(?=.*[a-z A-Z])(?=.*[0-9])(?=.{6,})/,
        // "Must be at least 6 characters & number include"
      ),
    password: Yup.string()
      .required(t("Enter password"))
      .min(8, t("Please enter more than 8 character"))
      .matches(
        /^[a-zA-Z0-9@!#$%&*^]+$/,
        t("Password cannot contain white space")
        // /^(?=.*[a-z A-Z 0-9])(?=.{8,})/,
        // "Must be at least 6 characters"
      ),
    confirm_password: Yup.string()
      .required(t("Enter confirm password"))
      .oneOf([Yup.ref("password"), null], t("Is not same with your New password")),
    phone_number: Yup.string()
      .required(t("Enter phone number"))
      .min(10, t("Please enter more than or equal to 10 character"))
      .max(13, t("Must be exactly 13 digits"))
      .matches(/^([6]{1}|\+?[0]{1})([0-9]{9,11})$/g, t("Must be phone format")),
    invitation_code: Yup.string().required(t("Enter referral code")),
  });

  async function onSubmit(
    data,
    setFieldError,
    navigate,
    setSubmitting,
    resetForm,
    defaultData
  ) {
    try {
      const { data: response } = await authServices.register({ ...data });
      if (data) {
        // authServices.loginWithToken(response.data, data.phone_number)
        const message = response.message
        sweetAlertHelper({
          title: t("Notification"),
          html: t(`message`,{message}),
          showCancelButton: false,
          showConfirmButton: false,
        });
        resetForm({ defaultData });

        navigate("/");
      }
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors;
        // toast.dismiss()
        if (errors && Object.keys(errors).length > 0) {
          // eslint-disable-next-line array-callback-return
          Object.keys(errors).map((item, i) => {
            setFieldError(item, errors[item]);
          });
        }
      }
    }
    setSubmitting(false);
  }
  return (
    <MDBContainer className="register-container size-control g-0">
      <Formik
        initialValues={data}
        validationSchema={registerSchema}
        onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
          onSubmit(
            values,
            setFieldError,
            navigate,
            setSubmitting,
            resetForm,
            data
          );
        }}
      >
        {({ setFieldValue, errors, isSubmitting, isValid, readOnly }) => (
          <Form>
            {FormikForm.renderInput(
              "username",
              t("Your Username"),
              "text",
              "",
              "",
              false,
              errors
            )}
            {FormikForm.renderInput(
              "password",
              t("New Password"),
              "text",
              "new-password",
              "key",
              false,
              errors
            )}
            {FormikForm.renderInput(
              "confirm_password",
              t("Confirm Password"),
              "text",
              "new-password",
              "key",
              false,
              errors
            )}
            {FormikForm.renderInput(
              "phone_number",
              t("Phone Number"),
              "number",
              "",
              "",
              false,
              errors
            )}
            {FormikForm.renderInput(
              "invitation_code",
              t("Referral By"),
              "text",
              "off",
              "",
              searchParams.get("referral_code") ? true : false,
              errors
            )}

            <MDBRow>
              <MDBCol className="register-text">
                {t("You must have referral code to create account , click")}{" "}
                <a href="https://t.me/mct_cs">
                  <span
                    className="mediumgrey-font"
                    style={{ color: "#0d6efd" }}
                  >
                    {t("here")}{" "}
                  </span>
                </a>
                {t("if you dont have it.")}
              </MDBCol>
            </MDBRow>
            {FormikForm.renderButton(
              t("Create Account"),
              isSubmitting,
              isValid,
              "register-submit-button"
            )}
          </Form>
        )}
      </Formik>
    </MDBContainer>
  );
};

async function onSubmit(
  data,
  setFieldError,
  navigate,
  setSubmitting,
  resetForm,
  defaultData
) {
  try {
    // const { data: response } = await authServices.register({ ...data })
    const response = {};
    const token = response.data.token || "";

    if (token) {
      // authServices.loginWithToken(response.data, data.phone_number)
      sweetAlertHelper({
        title: "通知",
        html: `恭喜你,你已注册成功！`,
        showCancelButton: false,
        showConfirmButton: false,
      });
      resetForm({ defaultData });

      navigate("/");
    }
  } catch (ex) {
    if (ex && ex.response.status === 422) {
      const errors = ex.response.data.errors;
      // toast.dismiss()
      if (errors && Object.keys(errors).length > 0) {
        // eslint-disable-next-line array-callback-return
        Object.keys(errors).map((item, i) => {
          setFieldError(item, errors[item]);
        });
      }
    }
  }
  setSubmitting(false);
}

export default RegisterForm;
