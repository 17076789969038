import apiServices from "../services/api.services"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const promotion = createAsyncThunk("get/promotion",
    async() => {
        const response = await apiServices.getPromotion()
        return response.data
    }  
)

const initialState = {
    promotion: [],
    Loading: true
}
// promotion ? { Loading: true, promotion } : { promotion: null }
const promotionSlice = createSlice({
    name:"promotion",
    initialState,
    extraReducers:{
        [promotion.pending]:(state, action) => {
            state.Loading = true;
        },
        [promotion.fulfilled]:(state,action) => {
            state.Loading = false;
            state.promotion = action.payload
        },
        [promotion.rejected]:(state,action) => {
            state.Loading = false;
        },
    }
})

const { reducer } = promotionSlice
export default reducer