import React from "react";
import { MDBNavbar, MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useLocation, useNavigate } from "react-router-dom";

import chip1 from "../../assets/images/chip/chip1.png"
import chip5 from "../../assets/images/chip/chip5.png"
import chip10 from "../../assets/images/chip/chip10.png"
import chip50 from "../../assets/images/chip/chip50.png"
import chip100 from "../../assets/images/chip/chip100.png"


const Navbar = ({ chip, totalChip, handleClick, decreaseTotalChip,clearAllTotalChip }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    navigate(url);
  };
  
  return (
    <MDBNavbar fixed="bottom" className="navbar-bottom" bgColor="light">
      <MDBContainer className="navbar-two-container">     
        <MDBContainer>
          <MDBRow>
            <MDBCol className="p-0">
              <img src={chip1} width="45" onClick={()=>{handleClick(1)}}/>
            </MDBCol>
            <MDBCol className="p-0">
              <img src={chip5} width="45" onClick={()=>{handleClick(5)}}/>
            </MDBCol>
            <MDBCol className="p-0">
              <img src={chip10} width="45" onClick={()=>{handleClick(10)}}/>
            </MDBCol>
            <MDBCol className="p-0">
              <img src={chip50} width="45" onClick={()=>{handleClick(50)}}/>
            </MDBCol>
            <MDBCol className="p-0">
              <img src={chip100} width="45" onClick={()=>{handleClick(100)}}/>
            </MDBCol>
          </MDBRow>
          <MDBRow style={{marginBottom: "13px"}}>
            <MDBCol className="betting-detail">
              <MDBCol>TOTAL WIN</MDBCol>
              <MDBCol>0</MDBCol>
            </MDBCol>
            <MDBCol className="betting-detail">
              <MDBCol>TOTAL BET</MDBCol>
              <MDBCol>{totalChip}</MDBCol>
            </MDBCol>            
          </MDBRow>
          {/* <MDBRow>
            <MDBCol className="betting-detail">
            <MDBCol>TOTAL BET</MDBCol>
            <MDBCol>0</MDBCol>
            </MDBCol> 
          </MDBRow> */}
        </MDBContainer>
        <button className="buttonDark2 buttonRound2">
	        <span className="label2" data-char="CLEAR ALL" onClick={()=>{
            clearAllTotalChip()
          }}></span>
        </button>
        <button className="buttonDark buttonRound">
	        <span className="label" data-char="DEAL"></span>
        </button>
        <button className="buttonDark2 buttonRound2">
	        <span className="label2" data-char="UNDO" onClick={()=>{
            decreaseTotalChip(chip)
          }}></span>
        </button>    
      </MDBContainer>
    </MDBNavbar>
  );
};

export default Navbar;
