import { MDBContainer, MDBRow, MDBIcon } from "mdb-react-ui-kit";
import { useNavigate, NavLink } from "react-router-dom";

const Header = ({ setHideLoginForm }) => {
  const navigate = useNavigate();

  return (
    <MDBContainer className="login-header g-0">
      <MDBRow>
        <MDBIcon
          className="login-back-icon"
          fas
          size="lg"
          icon="angle-left"
          onClick={
            () => setHideLoginForm("")
            // navigate("/")
          }
        />
        {/* <NavLink
          className="login-text no-decoration text-color-white"
          to="/register"
        > */}
        <span
          onClick={() => {
            setHideLoginForm("Register");
          }}
        >
          Register Now
        </span>
        {/* </NavLink> */}
      </MDBRow>
    </MDBContainer>
  );
};

export default Header;
