import React, { useState, useEffect, Suspense } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";

//Component
import Header from "../../components/headers/header-six";

//Local Component
import WalletCard from "./component/wallet.card";
import Action from "./component/action"
import Topup from "./component/topup";
import Withdrawal from "./component/withdrawal"

const Wallet = () => {
  const [showBox, setShowBox] = useState("topup")

  return (
    <React.Fragment>
      <Header />
      <MDBContainer className="wallet-container g-0">
        <WalletCard />
        <Action setShowBox={setShowBox} />
        {showBox === "topup" ? <Topup /> : <Withdrawal />}
      </MDBContainer>
    </React.Fragment>
  );
};

export default Wallet;
