import { MDBContainer } from "mdb-react-ui-kit"
import React,{useState} from "react"
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

//image 
import Images from "../../../assets/images/promotion.png"

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const CarouselPromotion = () => {
    const [ showGift , setShowGift ] = useState(false)
    console.log("showgift",showGift)
    return(
        <React.Fragment>
            <MDBContainer>
                <Carousel responsive={responsive}>
                    <div><img src={Images} width="300" height="350" /> </div>
                    <div>Item 2</div>
                    <div>Item 3</div>
                    <div>Item 4</div>
                </Carousel>
            </MDBContainer>
        </React.Fragment>
    )
}

export default CarouselPromotion