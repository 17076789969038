import apiServices from "../../services/api.services"
import authServices from "../../services/auth.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const report = createAsyncThunk("todayReport",
    async({startDate,endDate}) => {
        const response = await apiServices.getTodayReport(startDate,endDate)
        return response.data
    }  
)

export const tradeHistory = createAsyncThunk("tradeHistory",
    async() => {
        const response = await apiServices.getTradeReport()
        return response.data
    }  
)

export const referral = createAsyncThunk("referralReport",
    async() => {
        const response = await apiServices.getReferral()
        return response.data
    }  
)

const initialState = {
    report: {},
    tradeHistory: {},
    referral: {},
    Loading: true,
}

const reportSlice = createSlice({
    name:"allHistory",
    initialState,
    reducers:{
        refreshHistory:(state)=>{
            state.Loading = true
        }
    },
    extraReducers:{
        [report.pending]:(state,action)=>{
            return{
                ...state,
                Loading: true,
            }
        },
        [report.fulfilled]:(state,action)=>{
            return{
                ...state,
                Loading: false,
                report: action.payload
            }
        },
        [tradeHistory.pending]:(state,action)=>{
            return{
                ...state,
                Loading: true,
            }
        },
        [tradeHistory.fulfilled]:(state,action)=>{
            return{
                ...state,
                Loading: false,
                tradeHistory: action.payload
            }
        },
        [referral.pending]:(state,action)=>{
            return{
                ...state,
                Loading: true,
            }
        },
        [referral.fulfilled]:(state,action)=>{
            return{
                ...state,
                Loading: false,
                referral: action.payload
            }
        },
    }
})

export const { refreshHistory } = reportSlice.actions
const { reducer } = reportSlice
export default reducer