import React from "react"
import { Field, ErrorMessage } from "formik"
import { MDBRow } from "mdb-react-ui-kit"

const Selectv2 = ({ name, label, options }) => {
  return (
    <MDBRow className="custom-field">
      <Field
        className="form-control selectv2_option"
        placeholder={label}
        name={name}
        as="select"
      >
        <option style={{ color: "#868181" }} disabled>
          {label}
        </option>
        {options.map((item) => {
          return (
            <option key={item.id} value={item.bank_name}>
              {item.bank_name}
            </option>
          )
        })}
      </Field>
      <ErrorMessage
        className="text-danger text-right error-message"
        name={name}
        component="div"
      />
    </MDBRow>
  )
}

export default Selectv2
