import apiServices from "../../services/api.services"
import authServices from "../../services/auth.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const wallet = createAsyncThunk("get/wallet",
    async() => {
        const response = await apiServices.getWalletBalance()
        return response.data
    }  
)

export const withdrawal = createAsyncThunk("post/withdrawal",
    async({amount},thunkAPI) => {
        try {
            const response = await authServices.Withdrawal(amount)
            return response.data
          } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.errors);
          }
    }  
)

export const topup = createAsyncThunk("post/topup",
    async({formData},thunkAPI) => {
        try {
            const response = await authServices.Topup(formData)
            return response.data
          } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.errors);
          }
    }  
)

const initialState = {
    wallet: {
        wallet_balance: "0.00"
    },
    withdrawal: {},
    topup: {},
    Loading: true,
}

const walletSlice = createSlice({
    name:"wallet",
    initialState,
    extraReducers:{
        [wallet.pending]:(state,action)=>{
            state.Loading = true;
        },
        [wallet.fulfilled]:(state,action)=>{
            state.Loading = false;
            state.wallet = action.payload
        },
        [withdrawal.pending]:(state,action)=>{
            state.Loading = true;
        },
        [withdrawal.fulfilled]:(state,action)=>{
            state.withdrawal = action.payload
        },
        [topup.pending]:(state,action)=>{
            state.Loading = true;
        },
        [topup.fulfilled]:(state,action)=>{
            state.topup = action.payload
        },
    }
})

const { reducer } = walletSlice
export default reducer