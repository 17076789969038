import { MDBBtn, MDBCol, MDBContainer,MDBRow } from "mdb-react-ui-kit"
import React from "react"
import Influencer1 from "../../../assets/images/influencer/peiyu.png"
import Influencer2 from "../../../assets/images/influencer/mssbabezzyumiko.png"

const Influencer = () => {
    return(
        <React.Fragment>
            <MDBContainer className="influencer-container p-0" >
            <MDBRow>
            <MDBCol className="influencer" style={{backgroundImage: `url(${Influencer1})`}}></MDBCol>
            <MDBCol className="influencer" style={{backgroundImage: `url(${Influencer2})`}}></MDBCol>
            </MDBRow>
            </MDBContainer>
        </React.Fragment>
    )
}

export default Influencer