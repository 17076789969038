import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { DatePicker } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next"
import moment from "moment";
import SideBar from "../../components/sidebars/sidebar-one";
import Header from "../../components/headers/header-three";
import Navbar from "../../components/element/navbar";
import Layout from "../../components/common/layout";
import ProtectedRoute from "../../components/common/protected.route";

// Reducer //
import { useDispatch,useSelector } from "react-redux";
import { report,refreshHistory } from "../../slices/profile/historySlice";

export const StyleWrapperDatePicker = styled.div`
  .ant-picker-panel {
    &:last-child {
      width: 0;
      .ant-picker-header {
        position: absolute;
        right: 0;
        .ant-picker-header-prev-btn,
        .ant-picker-header-view {
          visibility: hidden;
        }
      }

      .ant-picker-body {
        display: none;
      }

      @media (min-width: 1000px) {
        width: 280px !important;
        .ant-picker-header {
          position: relative;
          .ant-picker-header-prev-btn,
          .ant-picker-header-view {
            visibility: initial;
          }
        }

        .ant-picker-body {
          display: block;
        }
      }
    }
  }
`;

const TodayReport = () => {
  const dispatch = useDispatch()
  const todayReport = useSelector((state)=> state.report.report)
  const Loading = useSelector((state)=> state.report.Loading)
  const [endDate, setEndDate] = useState(moment().add(1, "days").format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(moment().subtract(0, "days").format("YYYY-MM-DD"));
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation()

  const panelRender = (panelNode) => (
    <StyleWrapperDatePicker>{panelNode}</StyleWrapperDatePicker>
  );

  useEffect(() => {
    dispatch(report({startDate,endDate}))
    return () => dispatch(refreshHistory())
  }, []);

  const onCloseDateRange = (open) => {
    setIsOpen(open);
  };

  return (
    <React.Fragment>
      <MDBContainer
        style={{ background: "#0F1620" }}
        className="profile-container g-0"
      >
        {/* <ProtectedRoute /> */}
        <Layout />
        <Header />
        <SideBar />
        <Navbar />
        {Loading ? (
          ""
        ) : (
          <MDBContainer className="profile-report-main">
            <MDBRow>
              <h1>{t("Today Report")}</h1>
            </MDBRow>
            {/* <DatePicker.RangePicker
              panelRender={panelRender}
              onChange={(values, dateString) => {
                setStartDate(dateString[0]);
                setEndDate(dateString[1]);
              }}
              onOpenChange={onCloseDateRange}
              defaultValue={[moment().subtract(0, "days"), moment()]}
              disabled={true}
            /> */}
            <div className="profile-report-box">
              <MDBRow>
                <h4>{t("Profit & Loss")}</h4>
              </MDBRow>
              <MDBRow
                className={
                  todayReport.profit_loss.toString().charAt(0) === "-"
                    ? "profile-report-box-price-highlight-red"
                    : "profile-report-box-price-highlight"
                }
              >
                <label>{todayReport.profit_loss}</label>
              </MDBRow>
              <div className="line"></div>
              <MDBRow>
                <MDBCol className="profile-report-box-col1">
                  <MDBCol>{t("Trade Amount")}</MDBCol>
                  <MDBCol>{todayReport.trade_prize}</MDBCol>
                </MDBCol>
                <MDBCol className="profile-report-box-col">
                  <MDBCol>{t("Win Amount")}</MDBCol>
                  <MDBCol>{todayReport.win_amount}</MDBCol>
                </MDBCol>
                <MDBCol className="profile-report-box-col1">
                  <MDBCol>{t("Event Bonus")}</MDBCol>
                  <MDBCol>{todayReport.event_bonus}</MDBCol>
                </MDBCol>
              </MDBRow>
              <div className="line"></div>
              <MDBRow>
                <MDBCol className="profile-report-box-col1">
                  <MDBCol>{t("Topup Amount")}</MDBCol>
                  <MDBCol>{todayReport.topup_amount}</MDBCol>
                </MDBCol>
                <MDBCol className="profile-report-box-col">
                  <MDBCol>{t("Withdraw Amount")}</MDBCol>
                  <MDBCol>{todayReport.withdrawal_amount}</MDBCol>
                </MDBCol>
                <MDBCol className="profile-report-box-col1">
                  <MDBCol>{t("Commision")}</MDBCol>
                  <MDBCol>{todayReport.commission}</MDBCol>
                </MDBCol>
              </MDBRow>
              <div className="line"></div>
              <MDBRow>
                <MDBCol className="profile-report-box-col1">
                  <MDBCol>{t("VIP Bonus")}</MDBCol>
                  <MDBCol>{todayReport.vip_bonus}</MDBCol>
                </MDBCol>
                <MDBCol className="profile-report-box-col">
                  <MDBCol></MDBCol>
                  <MDBCol></MDBCol>
                </MDBCol>
                <MDBCol className="profile-report-box-col1">
                  <MDBCol></MDBCol>
                  <MDBCol></MDBCol>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBContainer>
        )}
      </MDBContainer>
    </React.Fragment>
  );
};

export default TodayReport;
