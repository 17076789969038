import React, { useState } from "react";
import { MDBCol } from "mdb-react-ui-kit";
import _ from "lodash";
// import { Chip } from "./../../../assets/images";
import chip1 from "./../../../assets/images/chip/chip1.png"
import chip5 from "./../../../assets/images/chip/chip5.png"
import chip10 from "./../../../assets/images/chip/chip10.png"
import chip50 from "./../../../assets/images/chip/chip50.png"
import chip100 from "./../../../assets/images/chip/chip100.png"
import { useEffect } from "react";

const BetChip = ({
  chip,
  totalChip,
  number,
  className,
  increaseTotalChip,
  handleClick,
}) => {
  const [display, setDisplay] = useState(false);
  const [totalChips,setTotalChips] = useState([{1:["50","50","50","50","50","50","50","50","50","50","50","50","50"]},{10:["5","5"]}])
  // const [totalChip1, setTotalChip1] = useState([])
  // const [totalChip5, setTotalChip5] = useState([])
  // const [totalChip10, setTotalChip10] = useState([])
  // const [totalChip50, setTotalChip50] = useState([])
  // const [totalChip100, setTotalChip100] = useState([])
  const [countChip, setCountChip] = useState([])

  console.log("chips",totalChips)
  useEffect(()=>{
    if(totalChip === 0){
      setDisplay(false) 
    }
  },[totalChip])

  const CountChip = (chip) => {
    switch (chip) {
      case 1: 
          setCountChip([...countChip,chip])
          break;
      case 5:
          setCountChip([...countChip,chip])
          break;
      case 10:
          setCountChip([...countChip,chip])
          break;
      case 50:
          setCountChip([...countChip,chip])
          break;
      case 100:
          setCountChip([...countChip,chip])
          break;
      default:
          break;
    }
  }

  // console.log("totalChips",totalChips)

  const renderTableChip = () => {
    console.log("123 table chip")
    {totalChips && display === true ? (
      <> 
        {totalChips.map((item,index) => {
          {console.log("item",item)}
          for (var key in item){
            item[key].map((e,index)=>{
            {console.log("e",e)}
              return(
                <div key={index} className={`bet-chip`} style={{ bottom:`${index}px`}}> 
                  <img src={e === 1 
                    ? chip1
                    : e === 5 
                    ? chip5
                    : e === 10
                    ? chip10
                    : e === 50
                    ? chip50
                    : e === 100
                    ? chip100
                    : ""
                  } width="35" /> 
                   <span>hih</span>
                </div>
              )
            })
          }
        })}       
        </>
    ) : (
      <div>
        <span>{number}</span>
      </div>
    )}
  }
  return (
    <MDBCol
      onClick={() => {
        if(chip > 0){
          setDisplay(chip ? true : false);
          increaseTotalChip(chip)
          CountChip(chip)
        }
      }}
      // size={size}
      className={`g-0 ${className} ${number}`}
    >
      {totalChip && display === true ? (
        <> 
          {/* {chip && <div className="chip-label"><label>{totalChip}</label></div>} */}
          {/* {chip === 1 
            ? <img src={chip1} width="35" /> 
            : chip === 5 
            ? <img src={chip5} width="35" />
            : chip === 10
            ? <img src={chip10} width="35" />
            : chip === 50
            ? <img src={chip50} width="35" />
            : chip === 100
            ? <img src={chip100} width="35" />
            : ""
          }  */}
          {/* {countChip.map((index,bottom) => {
            {console.log("bottom",bottom)}
            return(
              <div key={bottom} className={`bet-chip`} style={{ bottom:`${bottom}px`}}> 
                <img src={index === 1 
                  ? chip1
                  : index === 5 
                  ? chip5
                  : index === 10
                  ? chip10
                  : index === 50
                  ? chip50
                  : index === 100
                  ? chip100
                  : ""
                } width="35" /> 
              </div>
            )
          })}        */}
         {totalChips.map((item, index) => {
            for (var key in item) {
              return item[key].map((chip,index) => {
                {
                  return (
                  <div key={index} className={`bet-chip`} style={{ bottom:`${index}px`}}> 
                    <img src={chip == 1 
                  ? chip1
                  : chip == 5 
                  ? chip5
                  : chip == 10
                  ? chip10
                  : chip == 50
                  ? chip50
                  : chip == 100
                  ? chip100
                  : ""
                } width="35" /> 
                    </div>
                  )
                }
              });
            }
          })}
          </>
      ) : (
        <div>
          <span>{number}</span>
          {/* <span className="bet-chip-rate">{rate}</span> */}
        </div>
      )}
    </MDBCol>
  );
};

export default BetChip;
