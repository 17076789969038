import { MDBBtn, MDBContainer } from "mdb-react-ui-kit"
import React from "react"
import { useNavigate } from "react-router-dom"
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import background from "../../../assets/images/background1.png"
const minuteSeconds = 60;

const timerProps = {
  isPlaying: true,
  size: 130,
  strokeWidth: 10
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div style={{fontSize:"x-small"}}>NEXT ROUND IN</div>
      <div style={{fontSize:"medium"}}>{time} {dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;

const BetCard = () => {
  const navigate = useNavigate()
    const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
    const endTime = stratTime + 243248; // use UNIX timestamp in seconds
  
    const remainingTime = endTime - stratTime;

    const handleClick = (minute) => {
      navigate("/bet/" + minute)
    }

    return(
        <React.Fragment>
            <MDBContainer className="bet-container p-0"
            style={{backgroundImage:`url(${background})`, backgroundSize: "cover"}}
            >
                <label>Win Everyday</label>
                <span className="bet-point">3,000</span>
                <MDBBtn onClick={()=>{
                  handleClick("1")
                }}>Play</MDBBtn>
                <div className="timer">
                    <CountdownCircleTimer
                        {...timerProps}
                        colors="#D4AF37"
                        duration={minuteSeconds}
                        initialRemainingTime={remainingTime % minuteSeconds}
                        onComplete={(totalElapsedTime) => ({
                        shouldRepeat: remainingTime - totalElapsedTime > 0
                        })}
                    >
                        {({ elapsedTime, color }) => (
                        <span style={{ color }}>
                            {renderTime("s", getTimeSeconds(elapsedTime))}
                        </span>
                        )}
                    </CountdownCircleTimer>
                </div>
            </MDBContainer>
        </React.Fragment>
    )
}

export default BetCard