import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Trans,useTranslation } from "react-i18next"

// Common Component //
import Header from "../../components/headers/header-three";
import SideBar from "../../components/sidebars/sidebar-one";
import ProtectedRoute from "../../components/common/protected.route";
import formikHelper from "../../helper/formik.helper";
import ModalBox from "./component/modalbox";

// Helper //
import { sweetAlertHelper } from "../../helper/sweetalert.helper";

// Image //
import Layout from "../../components/common/layout";
import Navbar from "../../components/element/navbar";

// Service //
import authServices from "../../services/auth.services";
import apiServices from "../../services/api.services";
import GuestModalBox from "../../components/modalbox/index";
import { useDispatch, useSelector } from "react-redux";
import { wallet,withdrawal } from "../../slices/wallet/walletSlice";
import { profile } from "../../slices/profile/profileSlice";
import { openModal } from "../../slices/modalbox/profile.modal.slice";

const CashOut = ({}) => {
  const dispatch = useDispatch();
  const {t,i18n} = useTranslation()
  // const wallet = useSelector((state) => state.api.wallet);

  const { isOpen } = useSelector((store) => store.modal)
  const profileDetail = useSelector((state) => state.profile.profile);
  const [showAddDetail, setShowAddDetail] = useState(false);
  const [accountDetail, setAccountDetail] = useState();
  const { token } = authServices.getCurrentUser();
  const [guestModalBox, setGuestModalBox] = useState(false);
  const [reload, setReload] = useState(false);
  
  // console.log("walletDetail",profileDetail)

  const closeAddDetail = () => {
    setShowAddDetail(false);
  };

  const getAccountDetailList = async () => {
    // const response = await apiServices.getAccountDetail();
    // if (response) {
    //   setAccountDetail(response.data);
    // }
  };

  useEffect(() => {
    if (token) {
      getAccountDetailList();
      dispatch(profile()).unwrap();
      dispatch(wallet()).unwrap();
    } else {
      setAccountDetail({ turnover_left: 0, daily_withdrawal_left: 3 });
    }
  }, [dispatch]);

  const schema = Yup.object({
    amount: Yup.number()
      .required(t("number only"))
      .typeError(t("you must specify a number"))
      .test("amount", t("minimum RM20"), (value) => value > 19),
  });

  async function onSubmit(
    values,
    setFieldError,
    setSubmitting,
    setReload,
    resetForm,
    defaultData
  ) {
    try {
      const { data: response } = await authServices.Withdrawal({ ...values });
      if (response) {
        const message = response.message
        setReload(!reload)
        sweetAlertHelper({
          title: t("Notification"),
          html: `Withdrawal Request Send`,
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors;
        // toast.dismiss()
        if (errors && Object.keys(errors).length > 0) {
          // eslint-disable-next-line array-callback-return
          Object.keys(errors).map((item, i) => {
            setFieldError(item, errors[item]);
          });
        }
      } else {
        if (ex.response.status === 401) {
          setGuestModalBox(true);
        } else {
          sweetAlertHelper({
            title: t("Notification"),
            html: `Withdrawal Fail`,
            showCancelButton: false,
            showConfirmButton: true,
            allowOutsideClick: false,
          });
        }
      }
    }
    setSubmitting(false);
  }

  const handleSubmit = (formValue,setFieldError,closeHandler) => {
    console.log("formValue",formValue)
      const { amount } = formValue
      dispatch(withdrawal({amount}))
        .unwrap()
        .then(() => {
          dispatch(wallet()).unwrap()
          sweetAlertHelper({
            title: t("Notification"),
            html: `Withdrawal Request Send`,
            showCancelButton: false,
            showConfirmButton: true,
            allowOutsideClick: false,
          });
        })
        .catch((e) => {
          if (e) {
            const errors = e
            // toast.dismiss()
            if (errors && Object.keys(errors).length > 0) {
              // eslint-disable-next-line array-callback-return
              Object.keys(errors).map((item, i) => {
                setFieldError(item, errors[item]);
              });
            }
          }
        });
    }

  return (
    <React.Fragment>
     { profileDetail && 
        <ModalBox
          show={isOpen}
          closeHandler={closeAddDetail}
          accountDetail={accountDetail}
          getAccountDetail={getAccountDetailList}
          setGuestModalBox={setGuestModalBox}
        />
     }
      <MDBContainer className="wallet-container">
        {/* <ProtectedRoute /> */}
        <Layout />
        <Header reload={reload}/>
        <SideBar setGuestModalBox={setGuestModalBox} />
        <Navbar />
        <GuestModalBox
          reload={reload}
          setReload={setReload}        
          guestModalBox={guestModalBox}
          setGuestModalBox={setGuestModalBox}
        />
        {/* <AddDetail show={showAddDetail} closeHandler={closeAddDetail} /> */}
        <MDBContainer style={{ paddingTop: "5em", paddingBottom: "10em" }}>
          <MDBRow>
            <MDBCol className="wallet-title">{t("Withdrawal Request")}</MDBCol>
          </MDBRow>
          {/* {profileDetail ? ( */}
            <>
              <Formik
                enableReinitialize={true}
                validateOnChange={true}
                initialValues={{
                  bank: profileDetail ? profileDetail.bank_type : "",
                  account: profileDetail ? profileDetail.bank_account_number:"",
                  holder_name: profileDetail ? profileDetail.fullname:"",
                  amount: "",
                }}
                validationSchema={schema}
                onSubmit={(values, {setFieldError,resetForm }) => { 
                  handleSubmit(values,setFieldError);
                  resetForm({ values: "" }); 
                //   (
                //   values,
                //   { setSubmitting, setFieldError, resetForm }
                // ) => {
                  if (!token) {
                    setGuestModalBox(true);
                  }
                }}
              >
                {({
                  setFieldValue,
                  errors,
                  isSubmitting,
                  isValid,
                  handleReset,
                }) => (
                  <Form>
                    <MDBContainer className="wallet-box">
                      <MDBRow className="wallet-box-row">
                        <MDBCol className="col-5 wallet-box-label">
                          {t("I have bank into")} :
                        </MDBCol>
                        <MDBCol>
                          {profileDetail && profileDetail.bank_type ? (
                            <>
                              {formikHelper.renderInput(
                                "bank",
                                "",
                                "text",
                                "off",
                                "",
                                true,
                                errors
                              )}
                            </>
                          ) : (
                            <label
                              className="wallet-box-addbtn"
                              onClick={() => {
                                if (token) {
                                  dispatch(openModal())
                                } else {
                                  setGuestModalBox(true);
                                }
                              }}
                            >
                              + {t("Add Detail")}
                            </label>
                          )}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="wallet-box-row">
                        <MDBCol className="col-5 wallet-box-label">
                          {t("Account")} :
                        </MDBCol>
                        <MDBCol>
                          {formikHelper.renderInput(
                            "account",
                            "",
                            "text",
                            "off",
                            "",
                            true,
                            errors
                          )}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="wallet-box-row">
                        <MDBCol className="col-5 wallet-box-label">
                          {t("Holder Name")} :
                        </MDBCol>
                        <MDBCol>
                          {formikHelper.renderInput(
                            "holder_name",
                            "",
                            "text",
                            "off",
                            "",
                            true,
                            errors
                          )}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="wallet-cashout-box-row">
                        <MDBCol className="col-5 wallet-box-label">
                          {t("Amount")} :
                        </MDBCol>
                        <MDBCol>
                          {formikHelper.renderInput(
                            "amount",
                            "",
                            "number",
                            "off",
                            "",
                            false,
                            errors
                          )}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="wallet-btn">
                        {formikHelper.renderButton(
                          t("Submit"),
                          "",
                          isValid,
                          "modalbox-btn"
                        )}
                      </MDBRow>
                    </MDBContainer>
                  </Form>
                )}
              </Formik>
              <MDBContainer className="wallet-box">
                {/* <Trans> */}
                  <label>{t("Check your")}</label>
                  <h3>{t("Withdrawal Detail")}</h3>
                  <ul>
                    <li>
                      {t("Turnover Left")} <span>{profileDetail ? profileDetail.turnover_left: 0}</span>
                    </li>
                    <li>
                      {t("Daily Withdraw Times Left")}
                      <span>{profileDetail ? profileDetail.daily_withdrawal_left : 3}</span>
                    </li>
                    <li>{t("Only allow 3 transaction withdraw per day.")}</li>
                    <li>{t("One time withdrawal requires 5-10 minutes.")}</li>
                    <li>{t("One time withdrawal minimum RM20,Maximum RM100000")}</li>
                    <li>
                      {t("Please contact our Customer Service if you have issue with your withdrawal")}
                    </li>
                  </ul>
                {/* </Trans> */}
              </MDBContainer>
            </>
          {/* ) : (
            ""
          )} */}
        </MDBContainer>
      </MDBContainer>
    </React.Fragment>
  );
};

export default CashOut;
