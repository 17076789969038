import React,{ useState,useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { MDBContainer,MDBRow,MDBCol } from "mdb-react-ui-kit";
import formikHelper from "../../../helper/formik.helper";
import * as Yup from "yup";
  
const Withdrawal = () => {
    const [data,setData] = useState({
        amount: "",
    })

    const schema = Yup.object({
        amount: Yup.string()
          .required("required")
          .test("amount", "minimum RM20", (value) => value > 20),
      });

    return(
        <MDBContainer>
            <div className="topup-container">
                <Formik
                    enableReinitialize={true}
                    validateOnChange={true}
                    initialValues={data}
                    validationSchema={schema}
                    >
                    {({ setFieldValue, isSubmitting, handleReset, isValid, errors }) => (
                        <Form>
                        <MDBRow className="topup-detail">
                            <MDBCol className="col-5 topup-label">Amount :</MDBCol>
                            <MDBCol>
                                {formikHelper.renderInput(
                                    "amount",
                                    "",
                                    "number",
                                    "",
                                    "",
                                    false,
                                    errors
                                )}
                            </MDBCol>
                        </MDBRow>
                        <div className="topup-btn">
                            {formikHelper.renderButton(
                            "Submit",
                            isSubmitting,
                            isValid,
                            "modalbox-btn"
                            )}
                        </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </MDBContainer>
    )
}

export default Withdrawal