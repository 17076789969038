import React from "react";
import { MDBRow, MDBBtn } from "mdb-react-ui-kit";

const Button = ({
  label,
  isSubmitting,
  dirty,
  isValid,
  className,
  onClick,
  ...rest
}) => {
  return (
    <MDBRow className={`custom-button ${className ? className : ""}`}>
      <MDBBtn
        // onClick={() => {
        //   onClick();
        // }}
        color="light"
        type="submit"
        className="btn"
        disabled={!isValid || isSubmitting}
      >
        {isSubmitting ? "Submitting" : label}
      </MDBBtn>
    </MDBRow>
  );
};

export default Button;
