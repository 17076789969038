import React, { useState, useEffect, Suspense } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";

//Image
import CoinIcon from "../../../assets/images/coin.svg"

const WalletCard = ({setShowBox}) => {
    const [buttonActive, setbuttonActive] = useState("topup")

  return (
      <MDBContainer>
        <MDBRow className="wallet-action">
            <MDBCol className="action-btn">
                <button 
                    className={buttonActive === "topup" ?"button-active":"button"} 
                    onClick={()=>{
                        setbuttonActive("topup")
                        setShowBox("topup")
                    }}
                >Topup</button>
            </MDBCol>
            <MDBCol className="action-btn">
                <button  
                    className={buttonActive === "withdrawal" ?"button-active":"button"}
                    onClick={()=>{
                        setbuttonActive("withdrawal")
                        setShowBox("withdrawal")
                    }}
                >Withdrawal</button>
            </MDBCol>
        </MDBRow>
      </MDBContainer>
  );
};

export default WalletCard;
