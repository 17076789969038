import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import betTable from "../../../assets/images/tableplate.png"

import chipArray from "../../../assets/images/chip"
import BetChip from "./bet.chip";

const BetPlace = ({chip,totalChip,increaseTotalChip}) => {
  return (
    <React.Fragment>
        <MDBContainer className="bet-place-container g-0">
            <div className="bet-table">
                <div>
                    <img src={betTable} width="150" height="50"/>
                    <select className="bet-table-label">
                        <option>1 min Table</option>
                        <option>3 min Table</option>
                        <option>5 min Table</option>
                        <option>10 min Table</option>
                    </select>
                </div>
            </div>
            <div className="bet-place">
                {/* <MDBRow>
                    <MDBCol className="border-color">
                        <span className="animal">PHOENIX</span>
                    </MDBCol>         
                    <MDBCol className="border-color">
                        <span className="animal">DROGON</span>
                    </MDBCol>                      
                </MDBRow> */}
                <MDBRow>
                    <MDBCol className="border-color">
                        <span className="special-text">BIG</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span className="special-text">SMALL</span>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="border-color">
                        <span className="special-text">ODD</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span className="special-text">EVEN</span>
                    </MDBCol> 
                </MDBRow>
                <MDBRow  className="bet-place-label"> TOTAL SCORE 
                <div></div>
                </MDBRow>
                <MDBRow>
                    <BetChip 
                        chip={chip}
                        totalChip={totalChip}
                        number={1}
                        className={`border-color`}
                        increaseTotalChip={increaseTotalChip}
                    />
                    <BetChip 
                        chip={chip}
                        totalChip={totalChip}
                        number={2}
                        className={`border-color`}
                        increaseTotalChip={increaseTotalChip}
                    />
                    <BetChip 
                        chip={chip}
                        totalChip={totalChip}
                        number={3}
                        className={`border-color`}
                        increaseTotalChip={increaseTotalChip}
                    />
                    <BetChip 
                        chip={chip}
                        totalChip={totalChip}
                        number={4}
                        className={`border-color`}
                        increaseTotalChip={increaseTotalChip}
                    />
                    <BetChip 
                        chip={chip}
                        totalChip={totalChip}
                        number={5}
                        className={`border-color`}
                        increaseTotalChip={increaseTotalChip}
                    />
                    <BetChip 
                        chip={chip}
                        totalChip={totalChip}
                        number={6}
                        className={`border-color`}
                        increaseTotalChip={increaseTotalChip}
                    />
                    <BetChip 
                        chip={chip}
                        totalChip={totalChip}
                        number={7}
                        className={`border-color`}
                        increaseTotalChip={increaseTotalChip}
                    />
                </MDBRow>
                <MDBRow>
                    <MDBCol className="border-color">
                        <span>8</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>9</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>10</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>11</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>12</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>13</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>14</span>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="border-color">
                        <span>15</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>16</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>17</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>18</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>19</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>20</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>21</span>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="border-color">
                        <span>22</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>23</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>24</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>25</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>26</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>27</span>
                    </MDBCol>
                    <MDBCol className="border-color">
                        <span>28</span>
                    </MDBCol>
                </MDBRow>
            </div>
        </MDBContainer>
    </React.Fragment>
  );
};

export default BetPlace;
