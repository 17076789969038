import apiServices from "../../services/api.services"
import authServices from "../../services/auth.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const walletHistory = createAsyncThunk("walletHistory",
    async({length}) => {
        const response = await apiServices.getWalletHistory(length)
        return response.data
    }  
)

export const history = createAsyncThunk("transactionHistory",
    async({length,start,startDate,endDate}) => {
        const response = await apiServices.getWalletHistory(length,start,startDate,endDate)
        return response.data
    }  
)

const initialState = {
    wallet_history: [],
    history: [],
    // onLoad: true,
    Fetching: false,
    isEnd: false
}

const historySlice = createSlice({
    name:"history",
    initialState,
    reducers:{
        removeWalletHistory:(state)=>{
            state.history = [];
        }
    },
    extraReducers:{
        [walletHistory.pending]:(state,action)=>{
            state.Loading = true
        },
        [walletHistory.fulfilled]:(state,action)=>{
            return { 
                ...state, 
                wallet_history: action.payload, 
                Loading: false, 
                isEnd: false,
            }
        },
        [history.pending]:(state,action)=>{
            return{
                ...state,
                history: [...state.history],
                Fetching: true,
                // Loading: false, 
                // onLoad: true ,
                isEnd: false
            }
        },
        [history.fulfilled]:(state,action)=>{
            return { 
                ...state, 
                history: [...state.history, ...action.payload], 
                // Loading: false,
                Fetching: false, 
                // onLoad: false,
                isEnd: action.payload
            }
        },
    }
})

export const { removeWalletHistory } = historySlice.actions
const { reducer } = historySlice
export default reducer