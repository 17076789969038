import { MDBBtn, MDBContainer,MDBRow } from "mdb-react-ui-kit"
import React from "react"
import NewsTicker from "react-advanced-news-ticker";

//image
import dragon from "../../../assets/images/dragon.png"
import snake from "../../../assets/images/snake.png"
import chicken from "../../../assets/images/chicken.png"
import background from "../../../assets/images/backgroundStar.jpg"


const winnerName = [ "ju***","er45e***","ppgw*"];
  

const Zodiac = () => {
    return(
        <React.Fragment>
            <MDBContainer className="zodiac-container p-0" 
            // style={{backgroundImage:`url(${background})`}}
            >
                <div className="dragon"><img src={dragon} width="70" height="65"/></div>
                <div className="snake"><img src={snake} width="70" height="65"/></div>
                <div className="chicken"><img src={chicken} width="70" height="65"/></div>
                <MDBContainer className="zodiac-result">
                    <MDBRow className="zodiac-row"> 1st 18,888,888 </MDBRow>
                    <MDBRow className="zodiac-row"> 2st 8,888,888 </MDBRow>
                    <MDBRow className="zodiac-row"> 3st 888,888 </MDBRow>
                </MDBContainer>
              
            </MDBContainer>
        </React.Fragment>
    )
}

export default Zodiac