import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

export const sweetAlertHelper = (element) => {
  const MySwal = withReactContent(Swal)

  return MySwal.fire({
    ...element,
    customClass: {
      title: "custom-sweet-alert-title",
    },
  }).then((result) => {})
}

export default {
  sweetAlertHelper,
}
