import React, { useState, useEffect, Suspense } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdb-react-ui-kit";

//Image
import CoinIcon from "../../../assets/images/coin.svg"

const WalletCard = () => {
  return (
      <MDBContainer>
        <MDBRow className="wallet-card">
            <MDBRow className="wallet-card-title">
                <MDBCol className="col-12 wallet-card-name">Wong Cai</MDBCol>
                <MDBCol  className="col-12 wallet-card-number">***** 33388</MDBCol>
            </MDBRow>
            <MDBRow className="wallet-card-amount">
                <span>
                    <img src={CoinIcon} height="30" />35,986
                </span>
            </MDBRow>
        </MDBRow>
      </MDBContainer>
  );
};

export default WalletCard;
