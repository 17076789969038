import React from "react";
import { Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";

const Select = ({ label, name, options, errors, ...rest }) => {
  const { t } = useTranslation()
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <Field
        className="form-control"
        as="select"
        id={name}
        name={name}
        {...rest}
      >
        <option>- {t("Please Select")} -</option>
        {options.map((option) => {
          return (
            <option key={option.id} value={option.bank_name}>
              {option.bank_code}
            </option>
          );
        })}
      </Field>
      {/* <ErrorMessage name={name} /> */}
      {errors[name] ? (
        <div className="text-danger text-right no-padding error-message">
          {errors[name]}
        </div>
      ) : null}
    </div>
  );
};

export default Select;
