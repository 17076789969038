import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
// import Dice from "../../../components/element/dice";
import Dice from "../../../components/element/dicev2"
// import DigitRoll from 'digit-roll-react'

const BetResult = () => {

  return (
    <React.Fragment>
        <MDBContainer className="bet-result-container g-0">
          <div className="dice">
            <Dice />
            <Dice />
            <Dice />
          </div>
        </MDBContainer>
    </React.Fragment>
  );
};

export default BetResult;