import { MDBContainer, MDBRow, MDBIcon, MDBCol, MDBBtn } from "mdb-react-ui-kit"
import { Link } from "react-router-dom";
import backIcon from "../../assets/images/backBtn.png"
const Header = () => {
  
  return (
    <MDBContainer className="header-five-container">
      <MDBRow className="p-3">
        <MDBCol className="back-button">
             <Link to="/"><img className="icon" src={backIcon} width="40" height="40"/></Link>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default Header
