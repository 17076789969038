import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ScrollTop from "./scroll.top";
import {
  Iphone5,
  Iphone6,
  IphonePlus,
  IphoneX,
  Iphone11proMax,
  Iphone12mini,
  Iphone13pro,
  Iphone13proMax,
  Iphone11,
} from "../../assets/splash.screen";

const Layout = () => {
  return (
    <React.Fragment>
      <ScrollTop />
      <HelmetProvider>
        <Helmet>
          <meta
            name="description"
            content="Welcome to Millenium Capital Trading"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <link
            href="https://use.fontawesome.com/releases/v5.15.1/css/all.css"
            rel="stylesheet"
          />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link href="https://fonts.cdnfonts.com/css/gotham" rel="stylesheet" />
          <meta name="HandheldFriendly" content="true" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content="Millenium Capital" />
          <meta name="apple-touch-fullscreen" content="yes" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          {/* iphone5,SE @2 */}
          <link
            href={Iphone5}
            media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
            rel="apple-touch-startup-image"
          />
          {/* iphone6,6s,7,8,SE-2022 @2 */}
          <link
            href={Iphone6}
            media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
            rel="apple-touch-startup-image"
          />
          {/* iphone6+,6s+,7+,8+ @3 */}
          <link
            href={IphonePlus}
            media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)"
            rel="apple-touch-startup-image"
          />
          {/* iphoneX,Xs,11pro,13Mini @3 */}
          <link
            href={IphoneX}
            media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
            rel="apple-touch-startup-image"
          />
          {/* iphoneXsMax,11proMax @3 */}
          <link
            href={Iphone11proMax}
            media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
            rel="apple-touch-startup-image"
          />
          {/* iphoneXR,11 @2 */}
          {/* <link
            href={Iphone11proMax}
            media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
            rel="apple-touch-startup-image"
          /> */}
          {/* iphone11 @2 */}
          <link
            href={Iphone11}
            media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
            rel="apple-touch-startup-image"
          />
          {/* iphone12mini @3 */}
          <link
            href={Iphone12mini}
            media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
            rel="apple-touch-startup-image"
          />
          {/* iphone12,13,12pro,13pro @3 */}
          <link
            href={Iphone13pro}
            media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3)"
            rel="apple-touch-startup-image"
          />
          {/* iphone12proMax,iphone13proMax @3 */}
          <link
            href={Iphone13proMax}
            media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3)"
            rel="apple-touch-startup-image"
          />
        </Helmet>
      </HelmetProvider>
    </React.Fragment>
  );
};

export default Layout;
