import React, { useEffect, useState } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBContainer,
  MDBIcon,
  MDBCol,
} from "mdb-react-ui-kit";

import { MessageCircle } from "./../../assets/images/index";
import apiServices from "../../services/api.services";
import authServices from "../../services/auth.services";
import { useDispatch, useSelector } from "react-redux";

const Header = ({ reload }) => {
  const [headerClass, setHeaderClass] = useState("");
  const [balance, setBalance] = useState(0);
  const { token } = authServices.getCurrentUser();
  const wallet = useSelector((state) => state.wallet.wallet);

  // const getWalletBalance = async () => {
  //   const response = await apiServices.getWalletBalance();

  //   if (response) {
  //     setBalance(response.data.wallet_balance);
  //   }
  // };

  // useEffect(() => {
  //   if (reload) {
  //     getWalletBalance();
  //   }
  //   if (balance === 0 && token) {
  //     getWalletBalance();
  //   }
  // }, [reload]);
  const handleScroll = (event) => {
    const currentScrollY = window.scrollY;
    // if (currentScrollY > 5) setHeaderClass("common-header-appear")
    // else setHeaderClass("common-header-disappear")
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
  }, []);

  return (
    <header className={`common-header-main ${headerClass}`}>
      <MDBNavbar className="common-header" expand="lg" light fixed>
        <div className="common-text col-sm-8">
          <span>Credit Balance : RM {wallet ? wallet.wallet_balance : "0"}</span>
        </div>
        <div className="common-icon col-sm-4">
          <a href="https://t.me/mct_cs">
            <img src={MessageCircle} />
          </a>
        </div>
      </MDBNavbar>
    </header>
  );
};

export default Header;
