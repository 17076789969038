import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import SplashImage from "./assets/images/MCT_LOGO_FILE/Logo-1-with-Name.png";
// import "./App.scss";
import "./App2.scss"

import Welcome from "./pages/Welcome/index";
import Login from "./pages/Login/index";
import Register from "./pages/Register/index";
// import Home from "./pages/Home/index";
import Home from "./pages/New_Home";
// import Profile from "./pages/Profile";
import Profile from "./pages/New_Profile";
import TodayReport from "./pages/Report";
import TradeHistory from "./pages/History";
import Referral from "./pages/Referral";
import Wallet from "./pages/Wallet/index.jsx";
import TopUp from "./pages/Topup";
import TopUpv2 from "./pages/Topupv2";
import CashOut from "./pages/Cashout";
import Transaction from "./pages/Transaction";
// import Bet from "./pages/Bet";
import Bet from "./pages/New_Bet"
import { MDBContainer } from "mdb-react-ui-kit";
import Promotion from "./pages/Promotion";


function App() {
  // const dispatch = useDispatch();
  // function checkAutoLogin() {
  //   const token =localStorage.getItem('token')
  //   if(!token){
  //     dispatch(logout())
  //     return
  //   }else{
  //     dispatch(login())
  //   }
  // } 

  // useEffect(() => {
  //   checkAutoLogin()
  // },[])
  return (
    <React.Fragment>
      <Routes>
        {/* Public Route */}
        {/* <Route path="/" exact element={<Welcome />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* Protected Route */}
        <Route path="/" element={<Home />} />
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/bet/:min" element={<Bet />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/topup" element={<TopUp />} />
        {/* <Route path="/topup" element={<TopUpv2 />} /> */}
        <Route path="/cashout" element={<CashOut />} />
        <Route path="/transaction" element={<Transaction />} />
        <Route path="/promotion" element={<Promotion />} />
        <Route path="/profile" element={<Profile />} />
        {/* <Route path="/profile" element={<Profile />} /> */}
        <Route path="/report" element={<TodayReport />} />
        <Route path="/history" element={<TradeHistory />} />
        <Route path="/referral" element={<Referral />} />
      </Routes>
    </React.Fragment>
  );
  // }
}

export default App;
