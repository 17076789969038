import React from "react"
import { Field, ErrorMessage } from "formik"

const TextArea = ({ label, name, ...rest }) => {
  return (
    <div className="form-group">
      <Field
        className="form-control"
        as="textarea"
        id={name}
        name={name}
        {...rest}
      />
      <ErrorMessage name={name} />
    </div>
  )
}

export default TextArea
