import React from "react"
import PhoneInput from "react-phone-number-input"
import { MDBRow } from "mdb-react-ui-kit"
import CN from "react-phone-number-input/locale/ru"
const MobileInput = ({ name, placeholder, setFieldValue, errors, ...rest }) => {
  // const [value, setValue] = useState()
  return (
    <MDBRow className="custom-phone-input">
      <PhoneInput
        international
        name={name}
        placeholder={placeholder}
        onChange={(e) => {
          setFieldValue(name, e)
        }}
        rules={{ required: true }}
        defaultCountry="CN"
        autoComplete="off"
        addInternationalOption={false}
        countries={[
          "HK",
          "MO",
          "CN",
          "TW",
          "SG",
          "MY",
          "US",
          "AU",
          "CA",
          "NZ",
          "JP",
          "KR",
        ]}
        labels={{
          HK: "香港",
          MO: "澳门",
          CN: "中国",
          TW: "中国台湾",
          SG: "新加坡",
          MY: "马来西亚",
          US: "美国",
          AU: "澳大利亚",
          CA: "加拿大",
          NZ: "新西兰",
          JP: "日本",
          KR: "韩国",
        }}
      ></PhoneInput>
      {errors[name] ? (
        <div
          className="text-danger text-right"
          style={{ marginBottom: "-13px", fontSize: "12px" }}
        >
          {errors[name]}
        </div>
      ) : null}
    </MDBRow>
  )
}

export default MobileInput
