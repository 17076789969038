import http from "./http.services";
import config from "../config.json";

const apiEndpoint = config.apiEndPoint + "/login";
const tokenKey = "token";

http.setToken(getToken());

async function login(username, password) {
  const { data } = await http.post(apiEndpoint, {
    username: username,
    password: password,
  });
  const token = data.data.token || "";
  localStorage.setItem(tokenKey, token);

  if (token) return true;
  else return false;
}

function loginWithToken(data) {
  localStorage.setItem(tokenKey, data.token);
}

function logout() {
  // localStorage.clear();
  localStorage.removeItem("token")
  localStorage.removeItem("username")
  localStorage.removeItem("pusherTransportTLS")
}

function getCurrentUser() {
  const token = localStorage.getItem(tokenKey);
  if (token) return { token };
  else return "";
}

function getToken() {
  return localStorage.getItem(tokenKey);
}

async function register(invitation_code, username, password, confirm_password, phone_number) {
  return await http.post(
    config.apiEndPoint + `/register?referral_code=${invitation_code}`,
    {
      phone_number: phone_number || "",
      password: password || "",
      invitation_code: invitation_code || "",
      confirm_password: confirm_password || "",
      username: username || "",
    }
  );
}

async function UpdateProfile(name,account_number,payment_name,phone_number) {
  const response = await http.post(config.apiEndPoint + "/update_profile", {
    name: name,
    account_number: account_number,
    payment_name: payment_name,
    phone_number: phone_number,
  });
  return response;
}

async function Topup(
  // amount, file, bank_type, setting_id, 
  formData) {
  const response = await http.post(config.apiEndPoint + "/topup", formData);
  return response;
}

async function Withdrawal(amount) {
  const response = await http.post(config.apiEndPoint + "/withdrawal", {
    amount: amount,
  });
  return response;
}

async function ChangePassword(password,new_password,confirm_new_password) {
  const response = await http.post(config.apiEndPoint + "/change_password", {
    password: password,
    new_password: new_password,
    confirm_new_password: confirm_new_password,
  });
  return response;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  loginWithToken,
  logout,
  getCurrentUser,
  getToken,
  register,
  UpdateProfile,
  ChangePassword,
  Topup,
  Withdrawal,
};
