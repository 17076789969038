import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
// import QRCode from "react-qr-code";
import QRCode from "qrcode.react";

import { CopyToClipboard } from "react-copy-to-clipboard";

import SideBar from "../../components/sidebars/sidebar-one";
import Header from "../../components/headers/header-three";
import ProtectedRoute from "../../components/common/protected.route";
import { useTranslation } from "react-i18next"
import MyReferral from "./component/my.referral";
import MyCommission from "./component/my.commission";
import CommissionRecord from "./component/commission.record";

// Image //
import { BGMain } from "../../assets/images/index";
import Layout from "../../components/common/layout";
import Navbar from "../../components/element/navbar";

// Reducer //
import { useDispatch,useSelector } from "react-redux";
import { referral,refreshHistory } from "../../slices/profile/historySlice";
import { ref } from "yup";

const Referral = () => {
  const dispatch = useDispatch()
  const {t,i18n} = useTranslation()
  const referralReport = useSelector((state)=> state.report.referral)
  const [copied, setCopied] = useState(false);

  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${referralReport.referral_qr}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    dispatch(referral())
    return () => dispatch(refreshHistory())
  },[])

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000 * 10);
    }
  }, [copied]);

  return (
    <React.Fragment>
      <MDBContainer
        style={{ background: "#0F1620" }}
        className="profile-container g-0"
      >
        {/* <ProtectedRoute /> */}
        <Header />
        <SideBar />
        <Navbar />
        <Layout />
        <MDBContainer className="profile-referral-container">
          <MDBContainer>
            <MDBRow className="profile-referral-code-row">
              {copied ? (
                <span className="profile-referral-copied">Copied.</span>
              ) : (
                ""
              )}
              <CopyToClipboard
                onCopy={() => {
                  setCopied(true);
                }}
                text={referralReport ? referralReport.referral_link : ""}
              >
                <MDBCol className="col-5 profile-referral-code-label">
                  {t("COPY")}
                </MDBCol>
              </CopyToClipboard>
              <MDBCol
                className="col-7 profile-referral-code"
                type="text"
                // onChange={(event) => setText(event.target.value)}
              >
                {referralReport ? referralReport.referral_qr : ""}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer className="profile-referral-qrcode">
            <div className="qrcode-wrapper">
              <QRCode
                id="qr-gen"
                size={128}
                value={referralReport ? referralReport.referral_link : ""}
                onClick={() => {
                  downloadQRCode();
                  setCopied(true);
                }}
              />
            </div>
          </MDBContainer>
          <MDBContainer className="profile-referral-box">
            <MyReferral />
            <MyCommission  />
            <CommissionRecord />
          </MDBContainer>
        </MDBContainer>
      </MDBContainer>
    </React.Fragment>
  );
};

export default Referral;
