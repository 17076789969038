import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next"
import ArrowRight from "../../../assets/images/arrow-right-circle-fill.svg"

const History = () => {

  return (
    <MDBContainer>
      {/* <Referral show={showContent} /> */}
      <MDBRow className="profile-box-container">
        <MDBRow className="g-0">
          <div className="profile-box-title">History</div>
        </MDBRow>
        <MDBContainer className="profile-box history">
          <MDBRow className="profile-box-row">
            <MDBCol className="profile-box-label">Report</MDBCol>
            <MDBCol className="profile-box-btn">
              <Link to="/profile"><img src={ArrowRight} width="25"/></Link>
            </MDBCol>
          </MDBRow>
          <MDBRow className="profile-box-row">
            <MDBCol className="profile-box-label">Transaction</MDBCol>
            <MDBCol className="profile-box-btn">
              <Link to="/profile"><img src={ArrowRight} width="25"/></Link>
            </MDBCol>
          </MDBRow>
          <MDBRow className="profile-box-row">
            <MDBCol className="profile-box-label">Referral</MDBCol>
            <MDBCol className="profile-box-btn">
              <Link to="/profile"><img src={ArrowRight} width="25"/></Link>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBRow>
    </MDBContainer>
  );
};

export default History;
