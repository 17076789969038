import apiServices from "../services/api.services"
import authServices from "../services/auth.services";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const bet = createAsyncThunk("bet_info",
    async({min}) => {
        const response = await apiServices.getBetInfo(min)
        return response.data
    }  
)

export const postBet = createAsyncThunk("post/bet",
    async({payload},thunkAPI) => {
        try {
            const response = await apiServices.postBet(payload)
            return response.data
          } catch (error) {
            console.log("error",error)
            return thunkAPI.rejectWithValue(error);
          }
    }  
)
const initialState = {
    username: "",
    last_open_prize: "000000000000",
    first: "0",
    second: "0",
    third: "0",
    last_3_number: [0,0,0],
    bet_history: [],
    bet_prizes: {},
    my_bet_history: [],
    current_bet: {
        prize_no: "000000000000"
    },
    postBet: {},
    flipFlag: false,
    Loading: true,
}

const betSlice = createSlice({
    name:"bet",
    initialState,
    reducers:{
        setFlipFlagIsTrue:(state)=>{
            return{
                ...state,
                flipFlag: true,
            }
        },
        setFlipFlagIsFalse:(state)=>{
           return{
                ...state,
                flipFlag: false,
           }
        }
    },
    extraReducers:{
        [bet.pending]:(state,action)=>{
            return{
                ...state,
                Loading: true
            }  
        },
        [bet.fulfilled]:(state,action)=>{
            return{
                username: action.payload.username,
                last_open_prize: action.payload.last_open_prize,
                first: action.payload.bet_history[0].result[0],
                second: action.payload.bet_history[0].result[1],
                third: action.payload.bet_history[0].result[2],
                last_3_number: action.payload.last_3_number,
                bet_history: action.payload.bet_history,
                bet_prizes: action.payload.bet_prizes,
                my_bet_history: action.payload.my_bet_history,
                current_bet: action.payload.current_bet,
                flipFlag: false,
                Loading: false,
            }

        },
        [postBet.pending]:(state,action)=>{
            return{
                ...state,
                Loading: true,
            }
        },
        [postBet.fulfilled]:(state,action)=>{
            return{
                ...state,
                postBet: action.payload,
                Loading: false
            }
        },
    }
})

export const { setFlipFlagIsTrue, setFlipFlagIsFalse } = betSlice.actions
const { reducer } = betSlice
export default reducer