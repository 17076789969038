import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { DatePicker, Space } from "antd";
import "antd/dist/antd.min.css";
import styled from "styled-components";
import moment from "moment";
import { useTranslation } from "react-i18next"
import SideBar from "./../../components/sidebars/sidebar-one";
import Header from "./../../components/headers/header-three";
import Layout from "./../../components/common/layout";
import Navbar from "./../../components/element/navbar";
import Loader from "../../assets/images/Loader.svg";

// Image //
import { BGMain } from "./../../assets/images/index";

import apiServices from "../../services/api.services";
import ProtectedRoute from "../../components/common/protected.route";

// Reducer //
import { useDispatch,useSelector } from "react-redux";
import { tradeHistory,refreshHistory } from "../../slices/profile/historySlice";

export const StyleWrapperDatePicker = styled.div`
  .ant-picker-panel {
    &:last-child {
      width: 0;
      .ant-picker-header {
        position: absolute;
        right: 0;
        .ant-picker-header-prev-btn,
        .ant-picker-header-view {
          visibility: hidden;
        }
      }

      .ant-picker-body {
        display: none;
      }

      @media (min-width: 1000px) {
        width: 280px !important;
        .ant-picker-header {
          position: relative;
          .ant-picker-header-prev-btn,
          .ant-picker-header-view {
            visibility: initial;
          }
        }

        .ant-picker-body {
          display: block;
        }
      }
    }
  }
`;

const TradeHistory = () => {
  const dispatch = useDispatch()
  const {t,i18n} = useTranslation()
  const tradeReport = useSelector((state)=> state.report.tradeHistory)
  const Loading = useSelector((state)=> state.report.Loading)
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  // const [tradeReport, setTradeReport] = useState();

  const panelRender = (panelNode) => (
    <StyleWrapperDatePicker>{panelNode}</StyleWrapperDatePicker>
  );

  useEffect(() => {
    // getTradeReport();
    dispatch(tradeHistory())
    return () => dispatch(refreshHistory())
  }, [dispatch]);

  // const getTradeReport = async () => {
  //   const response = await apiServices.getTradeReport(startDate, endDate);
  //   if (response) {
  //     setTradeReport(response.data);
  //   }
  // };
  // console.log("trade report ", tradeReport);
  return (
    <React.Fragment>
      <MDBContainer
        style={{ background: "#0F1620" }}
        className="profile-container g-0"
      >
        <ProtectedRoute />
        <Layout />
        <Navbar />
        <Header />
        <SideBar />

        <MDBContainer className="profile-trade-container">
          <MDBContainer>
            <MDBRow>
              <h1>{t("Full Trading History")}</h1>
            </MDBRow>
          </MDBContainer>
          {/* <DatePicker.RangePicker
            panelRender={panelRender}
            onChange={(values, dateString) => {
              setStartDate(dateString[0]);
              setEndDate(dateString[1]);
            }}
            // onOpenChange={onCloseDateRange}
            // defaultValue={[moment().subtract(3, "days"), moment()]}
          /> */}
          <MDBContainer className="profile-trade-box">
            <MDBContainer>
              {
                tradeReport && tradeReport.length > 0 ? 
                <>
                  <div className="vl"></div>
                  <div className="v2"></div>
                  <div className="v3"></div>
                  <div className="v4"></div>
                </>
                : ""
              }
              <MDBRow className="profile-trade-box-title">
                <MDBCol className="col-2">{t("Table")}</MDBCol>
                <MDBCol className="col-3 p-0">{t("Prize No")}</MDBCol>
                <MDBCol className="col-2">{t("Trade Amount")}</MDBCol>
                <MDBCol className="col-2">{t("Result")}</MDBCol>
                <MDBCol className="col-3">{t("Trade Details")}</MDBCol>
              </MDBRow>
            </MDBContainer>
            <div className="line"></div>
            {Loading ? (
              ""
            ) : (
             <MDBContainer className="profile-trade-box-content scroll">
                {tradeReport.map((reportlist, index) => (
                  <div key={index}>
                    <MDBRow className="profile-trade-box-content-row">
                      <MDBCol className="col-2 p-0">
                        {reportlist.table}{t("min")}
                      </MDBCol>
                      <MDBCol className="col-3 p-1">
                        {reportlist.prize_no}
                      </MDBCol>
                      <MDBCol className="col-2 p-0">
                        {/* {parseFloat(reportlist.trade_amount).toFixed(0)} */}
                        {reportlist.trade_amount}
                        {/* 1000000000000 */}
                      </MDBCol>
                      <MDBCol
                        className="col-2 p-0"
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {reportlist.win_amount === "0" ? (
                          <>
                            <span className="bold-text">
                              {reportlist.bet_result}
                            </span>
                            <br />
                            {t("Lose")}
                          </>
                        ) : reportlist.win_amount === "" ? (
                          t("Pending")
                        ) : (
                          <>
                            <span className="bold-text">
                              {reportlist.bet_result}
                            </span>
                            <br />
                            {t("Win")}
                            <br />
                            {reportlist.win_amount}
                          </>
                        )}
                      </MDBCol>
                      <MDBCol className="col-3">
                        <MDBRow className="box">
                          {reportlist.bet_number.map((ball, index) => (
                            <MDBCol key={index} className="col bet">
                              <div
                                className={
                                  ball === "O"
                                    ? "ball green"
                                    : ball === "S"
                                    ? "ball red"
                                    : ball === "E"
                                    ? "ball red"
                                    : ball === "B"
                                    ? "ball green"
                                    : "ball white"
                                }
                              >
                                {ball}
                              </div>
                            </MDBCol>
                          ))}
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                    <div className="line"></div>
                  </div>
                ))}
              </MDBContainer>
            )}
          </MDBContainer>
        </MDBContainer>
      </MDBContainer>
    </React.Fragment>
  );
};

export default TradeHistory;
