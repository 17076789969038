import React from "react"
import { Field, ErrorMessage } from "formik"

const RadioButton = ({ label, name, options, ...rest }) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <div class="form-check">
        <Field className="form-check-input" name={name}>
          {(formik) => {
            const { field } = formik
            return options.map((option) => {
              return (
                <div key={option.key}>
                  <input
                    type="radio"
                    id={option.value}
                    {...field}
                    {...rest}
                    value={option.value}
                    checked={field.value === option.value}
                  />
                  <label className="form-check-label" htmlFor={option.value}>
                    {option.key}
                  </label>
                </div>
              )
            })
          }}
        </Field>
        <ErrorMessage name={name} />
      </div>
    </div>
  )
}

export default RadioButton
