import { MDBContainer } from "mdb-react-ui-kit"
import React from "react"
import { useState } from "react";
import { useEffect } from "react";
import Navbar from "../../components/element/navbar"

// Component
import Header from "../../components/headers/header-four"
import ModalBox from "../../components/modalbox"

//Local Component
import AccountDetail from "./component/account.detail"
import History from "./component/history"

const Profile = () => {
  const [ showGift , setShowGift ] = useState(false)
  console.log("showgift",showGift)
  return (
    <React.Fragment>
      <ModalBox show={showGift} setShowGift={setShowGift} />
      <Header />
        <MDBContainer className="profile-container">
          <AccountDetail />
          <History />
        </MDBContainer>
      <Navbar setShowGift={setShowGift} />
  </React.Fragment>
  );
};

export default Profile;
