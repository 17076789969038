import { MDBBtn, MDBContainer,MDBRow } from "mdb-react-ui-kit"
import React,{useEffect} from "react"
import NewsTicker from "react-advanced-news-ticker";
import CountUp from 'react-countup';

//image
import jackpotIcon from "../../../assets/images/jackpot.png"
import background from "../../../assets/images/background-grils.png"
import backgroundMain from "../../../assets/images/backgroundStar.gif"
import neonBox from "../../../assets/images/neonbox.png"

const winnerName = [ "ju***","er45e***","ppgw*"];
  

const Jackpot = () => {
    const [count, setCount] = React.useState(35607099);

    useEffect(
            () => {
                const timer = () => {
                    setCount(count + 1);
                }
    
                // if you want it to finish at some point
                if (count >= 100000000) {
                    return;
                }
                const id = setInterval(timer, 1000);
                return () => clearInterval(id);
            },
            [count]
        );
    return(
        <React.Fragment>
            <MDBContainer className="jackpot-container p-0"
            style={{backgroundImage:`url(${backgroundMain})`}}
            >
                <MDBRow className="jackpot-player-label">Player</MDBRow>
                <NewsTicker className="jackpot-ticker">
                    {winnerName.map((listing,index) => {
                    // console.log("listing",listing)
                    return(
                        <div key={index} className="jackpot-ticker-row">
                        <span className="jackpot-ticker-name">{listing}</span>
                        <br />
                        <span className="jackpot-ticker-amount">
                            RM {Math.floor(Math.random() * 9999)}.{Math.floor(Math.random() * 99)}
                        </span>
                        </div>
                    )
                    })}

                </NewsTicker>
                <img className="banner" src={jackpotIcon}  width="150" height="100" />
                <div className="countup"><span>{count}</span></div>
                <img className="neonBox" src={neonBox}  width="50%" height="35%" />
                <img className="background1" src={background}  width="160" height="100" />
            </MDBContainer>
        </React.Fragment>
    )
}

export default Jackpot