import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";

const AccountDetail = () => {

  return (
    <MDBContainer>
      <MDBRow className="profile-picture">
        <MDBCol  className="profile-picture-col">
          <div className="profile-title">鸿运当头</div>
        </MDBCol>
      </MDBRow>
      <MDBRow className="profile-detail">
        <MDBCol>
          <span className="profile-title">Xjs3998</span>
        </MDBCol>
      </MDBRow>
      <MDBRow className="profile-box-container">
        <MDBRow className="g-0">
          <div className="profile-box-title">Account Detail</div>
        </MDBRow>
        <MDBContainer className="profile-box">
          <MDBRow className="profile-box-row">
            <MDBCol className="profile-box-label">Full Name :</MDBCol>
            <MDBCol className="profile-box-text">Wong Cai</MDBCol>
          </MDBRow>
          <div className="line"></div>
          <MDBRow className="profile-box-row">
            <MDBCol className="profile-box-label">Bank Account :</MDBCol>
            <MDBCol className="profile-box-text">99880233388</MDBCol>
          </MDBRow>
          <div className="line"></div>
          <MDBRow className="profile-box-row">
            <MDBCol className="profile-box-label">Bank Type :</MDBCol>
            <MDBCol className="profile-box-text">Maybank</MDBCol>
          </MDBRow>
          <div className="line"></div>
          <MDBRow className="profile-box-row">
            <MDBCol className="profile-box-label">Phone Number :</MDBCol>
            <MDBCol className="profile-box-text">6012330998</MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBRow className="profle-box-comment">
        </MDBRow>
      </MDBRow>
    </MDBContainer>
  );
};

export default AccountDetail;
