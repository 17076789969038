import React from "react";
import { MDBNavbar, MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useLocation, useNavigate } from "react-router-dom";
import giftIcon from "../../assets/images/gift-box.png" 

const Navbar = ({ setShowGift }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    navigate(url);
  };

  const handleShowGift = () => {
    setShowGift(true);
  };
  
  return (
    <MDBNavbar fixed="bottom" className="navbar-bottom" bgColor="light">
      <MDBContainer className="navbar-container">
        <MDBCol
          className="navbar-col"
          onClick={() => handleNavigate("/")}
        >
          <span className={location.pathname === "/"? "active" : "navbar-label"}>Home</span>
        </MDBCol>
        <MDBCol
          className="navbar-col"
          onClick={() => handleNavigate("/promotion")}
        >
          <span className={location.pathname === "/promotion"? "active" : "navbar-label"}>Promotion</span>
        </MDBCol>
        <MDBCol
          className="navbar-col"
          onClick={() => handleNavigate("/profile")}
        >
          <span className={location.pathname === "/profile"? "active" : "navbar-label"}>
            Profile
          </span>
        </MDBCol>
        <MDBCol className="navbar-col">
          <span className="navbar-label"></span>
        </MDBCol>
        <span className="blink"></span>
        <MDBCol
          className="navbar-col gift"
          onClick={() => handleShowGift()}
        >
          <span className="gift-icon"><img src={giftIcon} width="45" height="45"/></span>
        </MDBCol>
      </MDBContainer>
    </MDBNavbar>
  );
};

export default Navbar;
