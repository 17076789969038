//React element
import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBModal, MDBModalDialog, MDBModalContent } from "mdb-react-ui-kit";
import CloseBtn from "../../assets/images/x-circle.png";

const ModalBox = ({ show,setShowGift }) => {
  const handleClose = () =>{
    setShowGift(false);
  }
    return (
        <MDBModal staticBackdrop tabIndex='-1' show={show} setShow={setShowGift}>
          <MDBModalDialog>
            <MDBModalContent>
              <div style={{ alignSelf: "flex-end" }}>
                <img
                  src={CloseBtn}
                  variant="default"
                  size="sm"
                  onClick={() => handleClose()}
                />
              </div>
            </MDBModalContent>
          </MDBModalDialog>  
        </MDBModal>
    );
};

export default ModalBox;
