import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"

const MyCommission = () => {
  const {t,i18n} = useTranslation()
  const referralReport = useSelector((state)=> state.report.referral)

  return (
    <React.Fragment>
      <MDBContainer>
        <MDBRow className="profile-box-row g-0">
          <MDBRow className="g-0">
            <div className="profile-box-label-1">{t("My Commission")}</div>
          </MDBRow>
          <MDBContainer className="profile-box">
            <MDBRow className="profile-box-row">
              <MDBCol className="col-6 profile-box-col">
                {t("Past Commission")}
              </MDBCol>
              <MDBCol className="col-6 profile-box-col2">
                {referralReport ? referralReport.total_commission : ""}
              </MDBCol>
            </MDBRow>
            <div className="line"></div>
            <MDBRow className="profile-box-row">
              <MDBCol className="col-6 profile-box-col">{t("Level 1")}</MDBCol>
              <MDBCol className="col-6 profile-box-col2">
                {referralReport ? referralReport.level_1_commission : ""}
              </MDBCol>
            </MDBRow>
            <div className="line"></div>
            <MDBRow className="profile-box-row">
              <MDBCol className="col-6 profile-box-col">{t("Level 2")}</MDBCol>
              <MDBCol className="col-6 profile-box-col2">
                {referralReport ? referralReport.level_2_commission : ""}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
      </MDBContainer>
    </React.Fragment>
  );
};

export default MyCommission;
