import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import profileReducer from "./slices/profile/profileSlice"
import walletReducer from "./slices/wallet/walletSlice"
import promotionReducer  from './slices/promotionSlice';
import homeReducer from "./slices/home/homeSlice"
import modalReducer from "./slices/modalbox/profile.modal.slice";
import historyReducer  from './slices/wallet/translationSlice';
import changePasswordReducer from './slices/profile/changePasswordSilce';
import reportReducer from './slices/profile/historySlice';
import betSlice from './slices/betSlice'

const reducer = {
  auth: authReducer,
  profile: profileReducer,
  report: reportReducer,
  changePassword: changePasswordReducer,
  wallet: walletReducer,
  promotion : promotionReducer,
  home: homeReducer,
  history: historyReducer,
  bet: betSlice,
  modal: modalReducer,
}
const store = configureStore({
  reducer: reducer,
  devTools: true,
})
export default store;