import React from "react"
import { Field, ErrorMessage } from "formik"

const Checkboxes = ({ label, name, options, ...rest }) => {
  return (
    <div className="form-check">
      <label>{label}</label>
      <Field className="form-check-input" name={name}>
        {(formik) => {
          const { field } = formik
          return options.map((option) => {
            return (
              <div key={option.key}>
                <input
                  type="checkbox"
                  id={option.value}
                  {...field}
                  {...rest}
                  value={option.value}
                  checked={field.value.includes(option.value)}
                />
                <label>{option.key}</label>
              </div>
            )
          })
        }}
      </Field>
      <ErrorMessage name={name} />
    </div>
  )
}

export default Checkboxes
